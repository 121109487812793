import React, { Component } from 'react';
import Cookies from 'js-cookie';
import _ from 'lodash';

import OfferBox from '../components/offerBox/OfferBox';
import OfferBoxList from '../components/offerBox/OfferBoxList';
import { getProductData } from '../lib/products';
import { capturePayment, chargePayment } from '../lib/api_service';
import history from '../history';
import { dataLayerPush } from '../lib/gtm';
import { Alert } from 'react-bootstrap';
import * as Sentry from '@sentry/browser';

import '../assets/stylesheets/pages/_special.scss';

const paypal = window.paypal;
const BILLING_ENV = process.env.REACT_APP_BILLING_ENV;

export default class SpecialPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      product: this.loadProduct(),
      paymentError: '',
      isLoading: false
    };
  }

  paypalButtonContainer = React.createRef();

  gtm = _.once(() => {
    if (this.props.appStateUtils.getState('dataLayerEvents').purchase_mp) {
      return;
    }
    this.props.appStateUtils.mergeState('dataLayerEvents', {
      purchase_mp: true
    });
    dataLayerPush({ event: 'purchase_mp' });
  });

  initPaypal() {
    paypal
      .Buttons({
        // FIXME: this should be set by env vars
        env: BILLING_ENV === 'production' ? 'production' : 'sandbox',
        style: {
          layout: 'horizontal', // horizontal | vertical
          size: 'responsive', // medium | large | responsive
          height: 55,
          shape: 'pill', // pill | rect
          color: 'gold', // gold | blue | silver | black
          tagline: 'false',
          fundingicons: 'false'
        },
        funding: {
          allowed: [paypal.FUNDING.CARD],
          disallowed: [paypal.FUNDING.CREDIT]
        },

        createSubscription: (data, actions) => {
          this.setState({ isLoading: true, paymentError: '' });
          return this.capturePayment('paypal')
            .then(result => {
              console.log('Created pending Paypal Order:', result);
              return result.order.merchantOrderId;
            })
            .catch(error => {
              this.paymentError(error, error.data.message);
              console.error('Payment Error: ', error.data);
              this.setState({ isLoading: false });
            });
        },

        onApprove: (data, actions) => {
          return this.chargePayment(data.subscriptionID)
            .then(result => {
              console.log('Payment Success: ', result);
              setTimeout(() => {
                this.paymentSuccess(result.order);
              }, 1000);
            })
            .catch(error => {
              this.paymentError(error, error.data.message);
              console.error('Payment Error: ', error.data);
              this.setState({ isLoading: false });
            });
        },

        onCancel: () => {
          this.setState({ isLoading: false });
        }
      })
      .render(this.paypalButtonContainer.current);
  }

  setCookies(order, customer) {
    // setCookies from special.php V1 that still have to be explained
    //setcookie("morand", Input::get($_SESSION, 'morand'), time()+30*24*60*60);
    //setcookie("special_version", $selected['path'], time()+30*24*60*60);
    let cookieOptions = { expires: 1 };
    Cookies.set('mqcid', '', cookieOptions);

    // cookies from order
    cookieOptions = { expires: 30 };
    Cookies.set('mprice', order.amount, cookieOptions);
    Cookies.set('mprice_num', order.amount, cookieOptions);
    Cookies.set('modid', order.orderId, cookieOptions);
    Cookies.set('mname', customer.fullName, cookieOptions);
    Cookies.set('mprice', order.amount, cookieOptions);
  }

  componentDidMount() {
    this.gtm();

    this.setCookies(this.props.appState.order, this.props.appState.customer);
    if (this.props.appState.order.paymentStrategy === 'paypal') {
      this.initPaypal();
    }
  }

  // TODO: remove this deprecated component state hook
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.appState.locale.countryCode !==
      prevProps.appState.locale.countryCode
    ) {
      this.setState({ product: this.loadProduct() });
    }
  }

  loadProduct() {
    return getProductData(
      'vip_plan',
      this.props.appState.locale.countryCode,
      2
    );
  }

  rejectPayment = event => {
    event.preventDefault();
    history.push('/success');
  };

  submitPayment = async event => {
    this.setState({ isLoading: true, paymentError: '' });
    event.preventDefault();
    const { order } = this.props.appState;
    let result;
    try {
      result = await this.capturePayment(order.paymentStrategy);
      console.log(result);
      this.paymentSuccess(result.order);
    } catch (error) {
      let message = error.data
        ? error.data.message.message || error.data.message
        : error.message;
      console.error('Payment Error: ', error.data || message);
      this.paymentError(error, message);
      this.setState({ isLoading: false });
    }
  };

  updateOrderUpsellState(order) {
    const { paymentStrategy } = this.props.appState.order;

    this.props.appStateUtils.mergeState('orderUpsell', {
      ...order,
      paymentStrategy,
      amount: this.state.product.price
    });
  }

  paymentSuccess(order) {
    this.updateOrderUpsellState(order);
    history.push('/success');
  }

  async capturePayment(strategy) {
    const { customer, order, locale } = this.props.appState;

    return await capturePayment({
      intent: 'capture',
      billerType: strategy,
      billerToken: null,
      paymentType: 'recurring',
      productIds: [this.state.product.id],
      customer: {
        fullName: customer.fullName,
        email: customer.email
      },
      locale,
      sessionToken: this.props.sessionToken,
      usePayToken: true,
      payToken: order.payToken
    });
  }

  // This is only used by Paypal
  async chargePayment(subscriptionId) {
    const { order } = this.props.appState;
    return await chargePayment({
      intent: 'charge',
      billerType: 'paypal',
      paymentType: 'recurring',
      orderToken: subscriptionId,
      sessionToken: this.props.sessionToken,
      usePayToken: true,
      payToken: order.payToken
    });
  }

  renderPaymentButton() {
    const { order } = this.props.appState;

    if (order.paymentStrategy === 'paypal') {
      return <div id="paypal-container" ref={this.paypalButtonContainer} />;
    }

    return (
      <button className="btn big-btn" onClick={this.submitPayment}>
        <span>Click Here To Get This Offer</span>Add To My Order
      </button>
    );
  }

  paymentError = (exception, message) => {
    Sentry.captureException(exception);
    Sentry.captureMessage('sessionToken of error: ', this.props.sessionToken);
    this.setState({
      paymentError: (
        <React.Fragment>
          <strong>Payment Declined:</strong> {message}
        </React.Fragment>
      )
    });
  };

  render() {
    const { language, currencyCode } = this.props.appState.locale;
    const currencyFormatter = new Intl.NumberFormat(language, {
      style: 'currency',
      currency: currencyCode
    });
    const { isLoading, product } = this.state;
    const showBeforePrice = product.price !== product.priceBefore;

    let paymentErrorBlock = '';
    if (this.state.paymentError !== '') {
      paymentErrorBlock = (
        <Alert variant="danger">{this.state.paymentError}</Alert>
      );
    }

    return (
      <React.Fragment>
        <section className="vip-headline mb-0">
          <div className="container">
            <div className="col-lg-6 col-md-10 col-sm-12 mx-auto px-0 pt-3">
              <h1 className="p-3 text-center mb-0">
                Get Your Results <br />
                <span className="font-weight-bold">And Keep Them Too!</span>
              </h1>
            </div>
          </div>
        </section>
        <section className="step-container special">
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-6 col-md-10 col-sm-12 mt-3">
                <div className="access-box px-4">
                  <h2 className="text-center mb-3">
                    Your Personal <br />
                    <span>Goal Specialist</span>
                  </h2>
                  <figure className="mobile-device coach">
                    <div className="mobile-frame" />
                    <div className="mobile-screen" />
                  </figure>
                  <p className="mb-0 mx-auto">
                    <span>On-demand guidance</span>. Get expert advice from our
                    extremely knowledgable goal specialists. They've worked with
                    thousands of clients - of all ages, shapes, and sizes - so
                    they'll know exactly what you need to really accelerate your
                    results!{' '}
                    <span className="font-weight-bold">
                      This is the one and ONLY opportunity you'll have to claim
                      your coach at this special low, low price
                    </span>
                    . By securing your spot now, you'll never pay more than the
                    listed price below. However, we will warn you - if you
                    decide to pass this up, we won't be able to offer it again.
                    Click "Add To My Order" below to get started without delay!
                  </p>
                </div>
              </div>
            </div>
            <div className="row justify-content-center mt-4">
              <div className="col-12 col-sm-10 col-md-10 col-lg-6">
                <OfferBox
                  ribbonText="VIP"
                  promoTextPrice="ONE-TIME OFFER"
                  variant="vip"
                >
                  <OfferBoxList text="On-demand access to your goal specialist via email" />
                  <OfferBoxList text="Weekly accountability to help you stay on track" />
                  <OfferBoxList text="Custom tips to help your results stick for good" />
                  <OfferBoxList text="Unlimited meal plan revisions" />
                </OfferBox>
                <h1 className="text-center mt-3 month mb-0">
                  {currencyFormatter.format(product.price)} <span>/mo</span>{' '}
                  <span
                    style={{
                      textDecoration: 'line-through',
                      color: '#dc0000 !important',
                      fontSize: '1.6rem',
                      fontWeight: '300',
                      display: showBeforePrice ? 'inline' : 'none'
                    }}
                  >
                    {currencyFormatter.format(product.priceBefore)}
                  </span>
                </h1>
                <h4 className="text-center mt-0 font-weight-normal mb-3">
                  <i className="fa fa-tag" /> Lifetime Discount Applied
                </h4>
                <div className="buttons text-center">
                  <h3 className="font-weight-light confirm-vip mt-1 mb-2">
                    Would you like to add this to your order?
                  </h3>
                  {/* Used to display form errors. */}
                  {paymentErrorBlock}
                  <div id="payment-container">
                    {isLoading ? (
                      <React.Fragment>
                        <div style={{ textAlign: 'center' }}>
                          <i className="fas fa-circle-notch fa-spin" />{' '}
                          Processing Order...
                        </div>
                      </React.Fragment>
                    ) : null}
                    <div
                      id="payment-button-container"
                      style={{ display: isLoading ? 'none' : 'block' }}
                    >
                      {this.renderPaymentButton()}
                    </div>
                  </div>

                  <br />
                  <p className="mt-3 terms-copy">
                    Cancel anytime by contacting us at support@macrofare.com.
                    Your subscription will renew every 30 days until you decide
                    to cancel. After you cancel, you will NEVER be billed again.
                    No hassle, no questions asked.
                  </p>
                  <hr />
                  <a
                    href="/success"
                    className="d-inline-block no-thanks"
                    onClick={this.rejectPayment}
                  >
                    No thanks, I confirm I am waiving my rights to secure this
                    lifetime discounted membership rate and understand I will
                    pay the full rate if I decide to join in the future.{' '}
                    <strong>Please take me to my meal plan</strong>.
                  </a>
                  {/* <a href="success<?php echo PAGE_EXT;?>?qid=<?php echo $quiz_token;?>" className="d-inline-block no_btn">No thanks, I confirm I am waiving my rights to secure this lifetime discounted membership rate and understand I will pay the full rate if I decide to join in the future. <strong>Please take me to my meal plan</strong>.</a> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
