import React, { Component } from 'react';
import { CardElement, injectStripe } from 'react-stripe-elements';

class StripeCheckoutForm extends Component {
  createToken = async event => {
    let { token } = await this.props.stripe.createToken({
      name: this.props.customer.fullName
    });
    return token;
  };

  handleSubmit = event => {
    event.preventDefault();
    this.props.submitPayment();
  };

  handleValidation = event => {
    this.props.cardValidationChanged(event.complete);
  };

  render() {
    return (
      <div className="checkout">
        <form onSubmit={this.handleSubmit}>
          <CardElement onChange={this.handleValidation} />
        </form>
      </div>
    );
  }
}

export default injectStripe(StripeCheckoutForm, { withRef: true });
