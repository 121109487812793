import React from 'react';
import Sha from '../assets/images/sha.jpg';

export default function VersionPage() {
  return (
    <div>
      <div style={shaStyle} />
      <div style={{ textAlign: 'center' }}>
        {process.env.REACT_APP_COMMIT_SHA}
      </div>
    </div>
  );
}

const shaStyle = {
  backgroundImage: `url(${Sha})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center center',
  margin: '10px auto',
  height: '480px',
  width: '100%'
};
