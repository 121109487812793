import React, { Component } from 'react';
import classNames from 'classnames';

export default class StepProgressStatic extends Component {
  percentage = stepNum => {
    const stepCount = 7;
    const percent = 100 / stepCount;
    const percentage = percent * parseInt(stepNum - 1);
    const progress = percentage + '%';

    return progress;
  };

  render() {
    const { step } = this.props;
    return (
      <section
        id="section_step_progress_top"
        className="banner inner d-none d-md-block "
      >
        <div className="container">
          <div className="col-12">
            <div className="step-progress">
              <div className="progress-wrap">
                <ul className="progress-point">
                  <li className={classNames({ active: step === 1 })}>
                    <span>Gender</span>
                  </li>
                  <li className={classNames({ active: step === 2 })}>
                    <span>Exercise</span>
                  </li>
                  <li className={classNames({ active: step === 3 })}>
                    <span>Everyday</span>
                  </li>
                  <li className={classNames({ active: step === 4 })}>
                    <span>Proteins</span>
                  </li>
                  <li className={classNames({ active: step === 5 })}>
                    <span>Produce</span>
                  </li>
                  <li className={classNames({ active: step === 6 })}>
                    <span>Products</span>
                  </li>
                  <li className={classNames({ active: step === 7 })}>
                    <span>Specs</span>
                  </li>
                  <li className={classNames({ active: step === 8 })}>
                    <span>Summary</span>
                  </li>
                </ul>
                <div className="progress-line">
                  <span
                    className="step-line"
                    style={{ width: this.percentage(step) }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
