import React from 'react';
import className from 'classnames';

export default function FeatureCard(props) {
  const figureClass = className('mobile-device', props.name);
  return (
    <div className="col-md-6 col-sm-12 mt-3">
      <div className="access-box px-4">
        <figure className={figureClass}>
          <div className="mobile-frame" />
          <div className="mobile-screen" />
        </figure>
        <h3 className="text-center">{props.title}</h3>
        <p className="mb-0 mx-auto">
          <span>{props.descriptionEmphasis}</span>. {props.description}
        </p>
      </div>
    </div>
  );
}
