import React, { Component } from 'react';
import history from '../../history';
import QuizContext from '../../store/QuizContext';
import { dataLayerPush } from '../../lib/gtm';

export default class Calculating extends Component {
  state = {
    position: 0
  };

  gtm() {
    dataLayerPush({ event: 'quizComplete' });
  }

  componentDidMount() {
    this.gtm();

    setTimeout(this.updateMessage, this.props.messageTime[0].time);
    this.quizSaved = this.saveQuiz().catch(
      console.error.bind(null, 'saveQuiz Fail:')
    );
    this.metricsLoaded = this.requestCalculatedMetricsResults()
      .then(this.requestWeightChart)
      .catch(console.error);
  }

  updateMessage = () => {
    var newPosition = this.state.position + 1;
    var next = this.props.messageTime[newPosition];

    if (next != null) {
      setTimeout(this.updateMessage, this.props.messageTime[newPosition].time);

      this.setState({
        position: newPosition
      });
    } else {
      Promise.all([this.quizSaved, this.metricsLoaded]).then(() => {
        history.push('/final');
      });
    }
  };

  render() {
    return (
      <QuizContext.Consumer>
        {({
          saveQuiz,
          requestCalculatedMetricsResults,
          requestWeightChart
        }) => {
          this.saveQuiz = saveQuiz;
          this.requestCalculatedMetricsResults = requestCalculatedMetricsResults;
          this.requestWeightChart = requestWeightChart;

          return (
            <div className="quiz-page">
              <section className="jumbotron banner inner">
                <div className="container">
                  <h1 className="mb-0">
                    Generating <br />
                    <span className="highlight-bg">Your Plan</span>
                  </h1>
                </div>
              </section>
              <section className="step-container">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-12 col-sm-10 col-md-10 col-lg-6">
                      <div id="loading" className="calculating-texts">
                        {this.props.messageTime[this.state.position].msg}
                      </div>
                      <div id="content">
                        <span className="expand" />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          );
        }}
      </QuizContext.Consumer>
    );
  }
}
