import React from 'react';
import PropTypes from 'prop-types';
import '../assets/stylesheets/components/_pageError.scss';
import { ReactComponent as Logo } from '../assets/images/logo_color.svg';

export default function PageError({ children }) {
  return (
    <section className="error-box">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12 col-lg-10 col-xl-8">
            <div className="disabled-box shadow">
              <div className="box-heading">
                <Logo />
              </div>
              <div className="row pt-lg-4 no-gutters justify-content-center text-center">
                <div className="col-md-12 col-lg-10 p-4 px-lg-0">
                  <div
                    className="alert alert-warning text-center"
                    role="alert"
                  >
                    <h2 className="mb-0">
                      <i className="fas fa-exclamation-triangle" />{' '}
                      {children ? children : 'Error!'}
                    </h2>
                  </div>
                  <p>
                    Something went wrong. If you think this is a technical
                    error, please use our{' '}
                    <a href="/contact" target="_blank">
                      contact form
                    </a>{' '}
                    or{' '}
                    <a
                      href="mailto:support@macrofare.com"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      email us
                    </a>{' '}
                    directly.
                  </p>
                  <div className="row justify-content-center pt-4 pb-3 px-2 px-sm-0 contact-widgets">
                    <div className="col-12 col-sm-7">
                      <i
                        className="fa fa-envelope align-middle mr-2"
                        aria-hidden="true"
                      />
                      <span className="align-middle d-inline-block text-left text">
                        <span className="d-block c-label">Email:</span>
                        <strong>
                          <a href="mailto:support@macrofare.com">
                            support@macrofare.com
                          </a>
                        </strong>
                      </span>
                    </div>
                  </div>
                </div>
                {/* <!-- .col --> */}
              </div>
              {/* <!-- .row --> */}
            </div>
            {/* <!-- .disabled-box --> */}
          </div>
        </div>
      </div>
      {/* <!-- .container --> */}
    </section>
  );
}

PageError.propTypes = {
  children: PropTypes.node
};
