import React, { Component } from 'react';

const Frames = window.Frames;
export default class CheckoutDotComForm extends Component {
  rootRef = React.createRef();
  state = {
    tokenError: false
  };

  constructor(props) {
    super(props);
    props.setSubmitFn(this.requestToken);
  }

  requestToken = event => {
    event && event.preventDefault();

    Frames.setCustomerName(this.props.customer.fullName);
    return Frames.submitCard()
      .then(data => {
        this.props.setPaymentToken(data.cardToken);
        // TODO: munge data for api, then call parent submitApi call
        return data;
      })
      .catch(error => {
        console.error(error);
        // TODO: normalize error and re-raise
      });
  };
  initFrames() {
    Frames.init({
      publicKey: this.props.publicKey,
      containerSelector: '#checkoutDotComContainer',
      cardValidationChanged: () => {
        this.props.cardValidationChanged(Frames.isCardValid());
      },
      cardSubmitted: () => {
        // REVIEW: not sure we care about this.
      }
    });
  }

  componentDidMount() {
    this.initFrames();
  }

  componentWillUnmount() {
    // clean up Frames handlers
  }

  render() {
    return (
      <div ref={this.rootRef} id="checkoutDotComContainer">
        <div style={{ display: !this.state.tokenError ? '' : 'none' }} />
        <form
          onSubmit={this.requestToken}
          id="payment-form"
          method="POST"
          action="https://merchant.com/charge-card"
        >
          <div className="frames-container" />
        </form>
      </div>
    );
  }
}
