import React from 'react';
import { Carousel } from 'react-bootstrap';

import { ReactComponent as HealthyFoodIcon } from '../assets/images/icon2/Healthy_food.svg';
import { ReactComponent as MentorIcon } from '../assets/images/icon2/Mentor.svg';
import { ReactComponent as GoalPeakIcon } from '../assets/images/icon2/Goal_peak.svg';
import { ReactComponent as ScaleOIcon } from '../assets/images/icon2/Scale-o.svg';
import { ReactComponent as CalendarOIcon } from '../assets/images/icon2/Calendar-o.svg';
import { ReactComponent as BasketIcon } from '../assets/images/icon2/Basket.svg';
import { ReactComponent as ThinkingIcon } from '../assets/images/icon2/Thinking.svg';
import { ReactComponent as VarietyIcon } from '../assets/images/icon2/Variety.svg';
import { ReactComponent as HealthIcon } from '../assets/images/icon2/Health.svg';
import { ReactComponent as GlucoseIcon } from '../assets/images/icon2/Glucose.svg';
import { ReactComponent as MedicalIcon } from '../assets/images/icon2/Medical.svg';
import { ReactComponent as MedicationIcon } from '../assets/images/icon2/Medication.svg';
import { ReactComponent as OverweightIcon } from '../assets/images/icon2/Overweight.svg';
import { ReactComponent as PulseIcon } from '../assets/images/icon2/Pulse.svg';
import { ReactComponent as LostIcon } from '../assets/images/icon2/Lost.svg';

import IMG_MobileIsolated3 from '../assets/images/mobile-isolated3.png';
import IMG_Omelete from '../assets/images/omelete.jpg';
import IMG_Satisfying1 from '../assets/images/group-02.jpg';
import IMG_Satisfying2 from '../assets/images/group-03.jpg';
import IMG_Satisfying3 from '../assets/images/group-03a.jpg';
import IMG_Satisfying4 from '../assets/images/group-04.jpg';
import IMG_Healthycouple from '../assets/images/healthycouple.jpg';
import IMG_WomanCoverMobile from '../assets/images/woman-cover-mobile.jpg';
import IMG_Elder from '../assets/images/group-01.jpg';

import Header from '../components/Header';
import LetsConnect from '../components/LetsConnect';
import Footer from '../components/Footer';

export default function StartQuizPage() {
  return (
    <div className="start-quiz-page">
      <Header />
      <section className="intro-home">
        <div className="about-us-mask h-100 pb-5">
          <div className="circle" />
          <div className="container">
            <div className="text-wrap">
              <div className="row">
                <div className="col-12 col-lg-5 offset-lg-2 col-md-12 col-sm-12">
                  <h1 className="mb-0">Planning your meals</h1>
                  <h1 className="font-weight-bold mb-4">
                    is easier than ever.
                  </h1>
                  <p>
                    It's not just a healthy option, it's the more YOU time
                    option. Plan weekly menus in minutes and the rest is
                    history. Did we mention the recipes are incredible too? Get
                    started today!
                  </p>
                  <div className="row d-flex justify-content-center">
                    <div className="col-6 col-lg-6 col-md-4 col-sm-6 px-2">
                      <a className="home-btn" href="/gender">
                        <span className="text">Take The Quiz</span>
                      </a>
                    </div>
                    <div className="col-6 col-lg-6 col-md-4 col-sm-6 px-2">
                      <a
                        href="#products"
                        className="home-btn home-btn-inverted"
                      >
                        <span className="text">Learn More</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-5 col-md-12 col-sm-12">
                  <img
                    className="mobile-isolated"
                    src={IMG_MobileIsolated3}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="what-we-provide" id="products">
        <div className="container">
          <div className="row no-gutters wwp-img mb-5">
            <div className="col-md-6">
              <img src={IMG_Omelete} width="100%" alt="" />
            </div>
            <div className="col-md-6 wwp-text">
              <h1 className="mb-2">
                <span>What we</span> provide
              </h1>
              <p className="mb-4">
                A program that's made to work specifically for you. Not someone
                else.
              </p>
              <div className="row">
                <div className="col-4 col-lg-3">
                  <div className="icon-wrap">
                    <HealthyFoodIcon />
                  </div>
                </div>
                <div className="col-4 col-lg-3">
                  <div className="icon-wrap">
                    <MentorIcon />
                  </div>
                </div>
                <div className="col-4 col-lg-3">
                  <div className="icon-wrap">
                    <GoalPeakIcon />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center">
            <div className="col-lg-4 col-md-6 my-3">
              <div className="product-wrap box-shadow">
                <h3 className="text-center mb-1 text-uppercase">
                  No Stress, No Thinking
                </h3>
                <h2 className="text-center mb-3">Meal scheduling</h2>
                <ul>
                  <li>Recipes that fit your tastes and goal.</li>
                  <li>Easy-to-follow &amp; budget friendly.</li>
                  <li>Grocery lists for quick shopping.</li>
                </ul>
                <a
                  className="btn btn-sm btn-primary home-btn-inverted start-plan mt-4"
                  href="/gender"
                >
                  <span className="text">Get meal planner</span>
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 my-3">
              <div className="product-wrap box-shadow">
                <h3 className="text-center mb-1 text-uppercase">
                  Precise &amp; Strategic
                </h3>
                <h2 className="text-center mb-3">Personal coaching</h2>
                <ul>
                  <li>Personalized advice, when you need it.</li>
                  <li>Accountability to help you stay on track.</li>
                  <li>Manage results with customized tips.</li>
                </ul>
                <a
                  className="btn btn-sm btn-primary home-btn-inverted start-plan mt-4"
                  href="/gender"
                >
                  <span className="text">Sign up for coach</span>
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 my-3">
              <div className="product-wrap box-shadow">
                <h3 className="text-center mb-1 text-uppercase">
                  Tools &amp; direction For
                </h3>
                <h2 className="text-center mb-3">Manageable results</h2>
                <ul>
                  <li>Over 70,000 users and growing</li>
                  <li>A realistic plan that is meant for you.</li>
                  <li>Support network you can count on.</li>
                </ul>
                <a
                  className="btn btn-sm btn-primary home-btn-inverted start-plan mt-4"
                  href="/gender"
                >
                  <span className="text">Start your journey</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="who-can-use">
        <div className="container">
          <h1 className="text-center mb-5">
            How <span>Macrofare</span> can help...
          </h1>

          <div className="row text-center">
            <div className="col-6 col-lg-2 col-md-4 col-sm-6 d-flex flex-wrap justify-content-center my-3">
              <div className="icon-wrap weight">
                <ScaleOIcon />
              </div>
              <h3 className="w-100 mt-4 mb-0">
                Weight <br />
                Management
              </h3>
            </div>

            <div className="col-6 col-lg-2 col-md-4 col-sm-6 d-flex flex-wrap justify-content-center my-3">
              <div className="icon-wrap schedule">
                <CalendarOIcon />
              </div>
              <h3 className="w-100 mt-4 mb-0">
                Busy <br />
                Schedules
              </h3>
            </div>

            <div className="col-6 col-lg-2 col-md-4 col-sm-6 d-flex flex-wrap justify-content-center my-3">
              <div className="icon-wrap budget">
                <BasketIcon />
              </div>
              <h3 className="w-100 mt-4 mb-0">
                Food <br />
                Budgeting
              </h3>
            </div>

            <div className="col-6 col-lg-2 col-md-4 col-sm-6 d-flex flex-wrap justify-content-center my-3">
              <div className="icon-wrap variety">
                <VarietyIcon />
              </div>
              <h3 className="w-100 mt-4 mb-0">
                Meal <br />
                Variety
              </h3>
            </div>

            <div className="col-6 col-lg-2 col-md-4 col-sm-6 d-flex flex-wrap justify-content-center my-3">
              <div className="icon-wrap habit">
                <ThinkingIcon />
              </div>
              <h3 className="w-100 mt-4 mb-0">
                Healthy <br />
                Habits
              </h3>
            </div>

            <div className="col-6 col-lg-2 col-md-4 col-sm-6 d-flex flex-wrap justify-content-center my-3">
              <div className="icon-wrap wellness">
                <HealthIcon />
              </div>
              <h3 className="w-100 mt-4 mb-0">
                Better <br />
                Wellness
              </h3>
            </div>
          </div>
        </div>
      </section>

      <section className="why-it-works">
        <div className="container">
          <h1 className="mb-2">
            <span>How it</span> works
          </h1>
          <p>
            In just a few simple steps, you too can start a journey to a
            healthier you.
          </p>
          <div className="row align-items-center how-it-works">
            <div className="col-12 col-lg-3 col-md-6 col-sm-12">
              <h2 className="font-weight-light">
                <span className="red">1.</span> Take the quiz
              </h2>
              <p>
                It takes less than 2 minutes! Answer questions to personalize
                your results.
              </p>
            </div>
            <div className="col-12 col-lg-3 col-md-6 col-sm-12">
              <h2 className="font-weight-light">
                <span className="red">2.</span> Set your goal
              </h2>
              <p>
                You set the pace and what you want to achieve. This is your
                plan.
              </p>
            </div>
            <div className="col-12 col-lg-3 col-md-6 col-sm-12">
              <h2 className="font-weight-light">
                <span className="red">3.</span> Follow the recipes
              </h2>
              <p>Your recipes taste as great as they are easy to prepare.</p>
            </div>
            <div className="col-12 col-lg-3 col-md-6 col-sm-12">
              <h2 className="font-weight-light">
                <span className="red">4.</span> Achieve your goal
              </h2>
              <p>
                It's as simple as that. We did all the hard work so you can
                focus on you.
              </p>
            </div>
          </div>
          <a
            className="btn btn-lg btn-primary home-btn-inverted btn-rounded start-plan mt-5"
            href="/gender"
          >
            <span className="text">Get started now</span>
          </a>
        </div>
      </section>

      <section className="recipes">
        <h1 className="text-center my-5 pb-4">
          Recipes that are <span>rewarding</span> and <span>satisfying</span>
          ...
        </h1>
        <div className="row no-gutters">
          <div className="col-6 col-md-3 col-sm-6">
            <img src={IMG_Satisfying1} width="100%" alt="" />
          </div>
          <div className="col-6 col-md-3 col-sm-6">
            <img src={IMG_Satisfying2} width="100%" alt="" />
          </div>
          <div className="col-6 col-md-3 col-sm-6">
            <img src={IMG_Satisfying3} width="100%" alt="" />
          </div>
          <div className="col-6 col-md-3 col-sm-6">
            <img src={IMG_Satisfying4} width="100%" alt="" />
          </div>
        </div>
      </section>

      <section className="testimonials" id="their-stories">
        <div className="h-100 py-5">
          <div className="container">
            <h1 className="text-left mb-2">
              How has it <span>helped others?</span>
            </h1>
            <div className="row">
              <div className="col-12 col-md-4 col-sm-12 mt-4">
                <img
                  src={IMG_Healthycouple}
                  width="100%"
                  className="first box-shadow"
                  alt=""
                />
                <img
                  src={IMG_Elder}
                  width="100%"
                  className="second box-shadow"
                  alt=""
                />
                <img
                  src={IMG_WomanCoverMobile}
                  width="100%"
                  className="third box-shadow"
                  alt=""
                />
              </div>
              <div className="col-12 col-md-8 col-sm-12">
                <Carousel className="carousel slide" interval={null}>
                  <Carousel.Item>
                    <div className="testimonial-wrap box-shadow">
                      <div className="testimonial-comment">
                        <p>
                          <i className="fa fa-quote-left" /> I am 62-year-old.
                          Somehow life just became crazy and I found myself
                          weighing 212 lbs. Yes, I have always been on a roller
                          coaster when it comes to my weight.
                        </p>
                        <p>
                          One of my daughters who is a fitness coach told me a
                          long time ago to use macros and not calories. I
                          seriously had no idea what she was talking about and
                          when we spoke it was more confusing than ever. Until
                          the Macrofare meal plan, shopping list, and recipes!
                        </p>
                        <p>
                          Four weeks into this new way of life my clothing was
                          just too big and the energy I had was crazy! I joined
                          a gym and work out 3 days a week with my husband! It
                          has just been exciting, liberating and empowering
                          beyond what I can describe.{' '}
                          <i className="fa fa-quote-right" />
                        </p>
                      </div>
                      <h3 className="text-center">
                        <span className="font-weight-normal">Kathleen D.</span>{' '}
                        lost 31 lbs in 9-weeks
                      </h3>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="testimonial-wrap box-shadow">
                      <div className="testimonial-comment">
                        <p>
                          <i className="fa fa-quote-left" /> I started Macrofare
                          at the end of September (2018) and I will be honest
                          and say I was surprised how much weight I lost. I
                          honestly lost 21 pounds in 8-weeks.
                        </p>
                        <p>
                          I followed the instructions to the tee except for a
                          couple of stumbles. I had lost so much!{' '}
                        </p>
                        <p>
                          {' '}
                          I was really surprised since all I did was watch what
                          I ate. I did not exercise much and lost so much. I am
                          honestly happier than I have ever been with the weight
                          loss. <i className="fa fa-quote-right" />
                        </p>
                      </div>
                      <h3 className="text-center">
                        <span className="font-weight-normal">Carla Z.</span>{' '}
                        lost 21 lbs in 8-weeks
                      </h3>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="testimonial-wrap box-shadow">
                      <div className="testimonial-comment">
                        <p>
                          <i className="fa fa-quote-left" /> I purchased
                          Macrofare a couple weeks ago and could not start the
                          program as my wife had purchased another program where
                          you had to count all the calories and fats. It was
                          like having another job to eat right.
                        </p>
                        <p>
                          I had to convince her to use your program. We’ve been
                          on it for about a week now and love it!
                        </p>
                        <p>
                          What I like about it is that I am full all the time
                          and have no cravings for the afternoon sugary snack. I
                          don’t feel bloated like I did before we started it.{' '}
                          <i className="fa fa-quote-right" />
                        </p>
                      </div>
                      <h3 className="text-center">
                        <span className="font-weight-normal">
                          Sean H. And His Wife
                        </span>{' '}
                        Love It
                      </h3>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="testimonial-wrap box-shadow">
                      <div className="testimonial-comment">
                        <p>
                          <i className="fa fa-quote-left" /> I've gotten on and
                          off several diets because I lose motivation when I see
                          no change in the first few weeks.{' '}
                        </p>
                        <p>
                          This is the first time I've managed to stay on a diet
                          for over 3 weeks. And that is only because I can see
                          the changes in my body.
                        </p>
                        <p>
                          The meal plan is great, I love the snack recipes. It
                          has already helped me with curbing my cravings.{' '}
                          <i className="fa fa-quote-right" />
                        </p>
                      </div>
                      <h3 className="text-center">
                        <span className="font-weight-normal">Shifa K.</span>{' '}
                        Curbed Cravings
                      </h3>
                    </div>
                  </Carousel.Item>
                </Carousel>
                {/* <!-- #carouselExampleControls --> */}
              </div>
            </div>
            {/* <!-- .row --> */}
          </div>
          {/* <!-- .container --> */}
        </div>
      </section>

      <section className="about-us" id="our-story">
        <div className="container">
          <h1 className="mb-5">
            Our <span>story</span>...
          </h1>
          <div className="row">
            <div className="col-12 col-md-6 col-sm-12">
              <h2 className="mb-5">
                Most of us don't have enough hours in the day to focus on the
                person that matters most. That's why we're building a better
                meal planning solution that makes sense for both your health and
                lifestyle.
              </h2>
            </div>
            <div className="col-12 col-md-6 col-sm-12">
              <p>
                Everyone has to eat, but with busy schedules and hectic
                lifestyles it can be tough figuring out what's next on the menu.
                If you don't have a plan then fast food and dining out becomes
                regular - but you may want to think twice.
              </p>
              <p>
                Approximately 84 million American adults - more than one-third —
                are pre-diabetics. Excess weight, a leading factor for chronic
                diseases like diabetes and heart disease that are responsible
                for 85% of all medical costs, affects over 36% of the U.S.
                population. It's a frightening number. Even more so because in
                many cases preventative measures can be taken to lower the risk
                and stop the surge.
              </p>
              <p>
                That's why our team of developers and health industry experts
                are committed to building an ever-growing personalized solution
                that can help change the way you think about food and better
                your health.{' '}
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="statistics">
        <div className="statistics- h-100 py-5">
          <div className="container">
            <h1 className="text-center mb-4">
              Why <span>healthcare</span> is on the rise...
            </h1>
            <div className="row text-center my-2 no-gutters">
              <div className="col-12 col-md-2 col-sm-12 d-flex flex-wrap justify-content-center">
                <div className="icon-wrap">
                  <GlucoseIcon />
                </div>
                <h3 className="w-100 mt-1 mb-0">30.3 million</h3>
                <p>US. diabetics</p>
              </div>
              <div className="col-12 col-md-2 col-sm-12 d-flex flex-wrap justify-content-center">
                <div className="icon-wrap">
                  <MedicalIcon />
                </div>
                <h3 className="w-100 mt-1 mb-0">84.1 million</h3>
                <p>US. pre-diabetics</p>
              </div>
              <div className="col-12 col-md-2 col-sm-12 d-flex flex-wrap justify-content-center">
                <div className="icon-wrap">
                  <MedicationIcon />
                </div>
                <h3 className="w-100 mt-1 mb-0">2-5x higher</h3>
                <p>Medical costs</p>
              </div>
              <div className="col-12 col-md-2 col-sm-12 d-flex flex-wrap justify-content-center">
                <div className="icon-wrap">
                  <OverweightIcon />
                </div>
                <h3 className="w-100 mt-1 mb-0">57.6 percent</h3>
                <p>U.S. overweight</p>
              </div>
              <div className="col-12 col-md-2 col-sm-12 d-flex flex-wrap justify-content-center">
                <div className="icon-wrap">
                  <PulseIcon />
                </div>
                <h3 className="w-100 mt-1 mb-0">2.5x higher</h3>
                <p>Risk of heart attack</p>
              </div>
              <div className="col-12 col-md-2 col-sm-12 d-flex flex-wrap justify-content-center">
                <div className="icon-wrap">
                  <LostIcon />
                </div>
                <h3 className="w-100 mt-1 mb-0">$444+ billion</h3>
                <p>Medical &amp; lost wages</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="join-team" id="careers">
        <div className="container">
          <div className="row mb-5">
            <div className="col">
              <h2 className="font-weight-bold title-blue">
                Help us make a difference.
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-4 col-sm-12 mb-3">
              <h3 className="font-weight-light">Accounting</h3>
              <ul className="list-unstyled">
                <li>Junior Accountant</li>
              </ul>
            </div>
            <div className="col-12 col-md-4 col-sm-12 mb-3">
              <h3 className="font-weight-light">Branding</h3>
              <ul className="list-unstyled">
                <li>Social Media Manager</li>
                <li>Brand/content strategist</li>
              </ul>
            </div>
            <div className="col-12 col-md-4 col-sm-12 mb-3">
              <h3 className="font-weight-light">Clinical</h3>
              <ul className="list-unstyled">
                <li>Dietitian - Remote/Independent Contractor</li>
                <li>Nutritionist (4-year B.A./B.S.)</li>
                <li>Research Scientist</li>
                <li>Research Associate</li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-4 col-sm-12 mb-3">
              <h3 className="font-weight-light">Customer Care</h3>
              <ul className="list-unstyled">
                <li>Customer Care Manager</li>
                <li>Customer Care Associate - Part Time</li>
              </ul>
            </div>
            <div className="col-12 col-md-4 col-sm-12 mb-3">
              <h3 className="font-weight-light">Design</h3>
              <ul className="list-unstyled">
                <li>Senior UX Designer</li>
                <li>Front-end Designer</li>
                <li>Brand/Creative Designer</li>
              </ul>
            </div>
            <div className="col-12 col-md-4 col-sm-12 mb-3">
              <h3 className="font-weight-light">Development</h3>
              <ul className="list-unstyled">
                <li>Front-end Developer</li>
                <li>Full Stack Developer</li>
                <li>QA Developer</li>
              </ul>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-12 col-md-4 col-sm-12 mb-3">
              <h3 className="font-weight-light">Marketing</h3>
              <ul className="list-unstyled">
                <li>E-mail Marketing Specialist</li>
                <li>Search Marketing Specialist</li>
                <li>Media Buyer (social)</li>
                <li>Media Buyer (display)</li>
              </ul>
            </div>
            <div className="col-12 col-md-4 col-sm-12 mb-3">
              <h3 className="font-weight-light">Operations</h3>
              <ul className="list-unstyled">
                <li>Operations Manager</li>
                <li>Junior Project Manager</li>
              </ul>
            </div>
          </div>
          <h3 className="font-weight-light">
            Do you have what it takes? Show us your skills at{' '}
            <a href="mailto:talent@macrofare.com">talent@macrofare.com</a>
          </h3>
        </div>
      </section>
      <LetsConnect />
      <Footer hideSocial />
    </div>
  );
}
