import React, { Component } from 'react';

export default class DisclaimerCopy extends Component {
  render() {
    return (
      <React.Fragment>
        <h3>INTRODUCTION</h3>
        <p>
          The information provided by Fizzus LLC (“we”, “us”, or “our”) on
          MacroFare.com (the “Site”) is for general informational purposes only.
          All information on the Site is provided in good faith, however we make
          no representation or warranty of any kind, express or implied,
          regarding the accuracy, adequacy, validity, reliability, availability
          or completeness of any information on the Site.
        </p>
        <p>
          Under no circumstance shall we have any liability to you for any loss
          or damage of any kind incurred as a result of the use of the site or
          reliance on any information provided on the site. Your use of the site
          and your reliance on any information on the site is solely at your own
          risk.
        </p>
        <h3>EXTERNAL LINKS DISCLAIMER FOR WEBSITE</h3>
        <p>
          The Site may contain (or you may be sent through the Site links to
          other websites or content belonging to or originating from third
          parties or links to websites and features in banners or other
          advertising. Such external links are not investigated, monitored, or
          checked for accuracy, adequacy, validity, reliability, availability or
          completeness by us.
        </p>
        <p>
          We do not warrant, endorse, guarantee, or assume responsibility for
          the accuracy or reliability of any information offered by third-party
          websites linked through the site or any website or feature linked in
          any banner or other advertising. We will not be a party to or in any
          way be responsible for monitoring any transaction between you and
          third-party providers of products or services.
        </p>
        <h3>PROFESSIONAL DISCLAIMER FOR WEBSITE</h3>
        <p>
          <strong>
            The Site cannot and does not contain medical, health, fitness, or
            nutritional advice.
          </strong>{' '}
          The medical, health, fitness, and nutritional information is provided
          for general informational and educational purposes only and is not a
          substitute for professional advice.
        </p>
        <p>
          Accordingly, before taking any actions based upon such information, we
          encourage you to consult with the appropriate professionals. We do not
          provide any kind of fitness or health advice. The use or reliance of
          any information contained on this site is solely at your own risk.
        </p>
        <h3>AFFILIATES DISCLAIMER FOR WEBSITE</h3>
        <p>
          The Site may contain links to affiliate websites, and we receive an
          affiliate commission for any purchases made by you on the affiliate
          website using such links.
        </p>
        <h3>TESTIMONIALS DISCLAIMER FOR WEBSITE</h3>
        <p>
          The Site may contain testimonials by users of our products and/or
          services. These testimonials reflect the real-life experiences and
          opinions of such users. However, the experiences are personal to those
          particular users, and may not necessarily be representative of all
          users of our products and/or services. We do not claim, and you should
          not assume, that all users will have the same experiences. Your
          individual results may vary.
        </p>
        <p>
          The testimonials on the Site are submitted in various forms such as
          text, audio and/or video, and are reviewed by us before being posted.
          They appear on the Site verbatim as given by the users, except for the
          correction of grammar or typing errors. Some testimonials may have
          been shortened for the sake of brevity where the full testimonial
          contained extraneous information not relevant to the general public.
        </p>
        <p>
          The views and opinions contained in the testimonials belong solely to
          the individual user and do not reflect our views and opinions.
        </p>
        <p>
          The testimonials on the Site are not intended, nor should they be
          construed, as claims that our products and/or services can be used to
          diagnose, treat, mitigate, cure, prevent or otherwise be used for any
          disease or medical condition. No testimonials have been clinically
          proven or evaluated.
        </p>
        <p>Last updated June 23, 2018</p>
      </React.Fragment>
    );
  }
}
