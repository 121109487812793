import _ from 'lodash';

export function extractAnswers(quiz) {
  return _.reduce(
    quiz,
    (memo, question) => {
      let answers = _.chain(question.answers)
        .filter({ selected: true })
        .omit('selected')
        .value();

      if (question.type !== 'multi' && answers.length > 1) {
        let errMsg = 'Only multichoice answers can have more than one answer.';
        throw errMsg;
      }

      let simplifiedQuestion = {
        id: question.id,
        name: question.name,
        answers
      };
      memo.questions.push(simplifiedQuestion);

      return memo;
    },
    { questions: [] }
  );
}

/*
{
  "age": "39",
  "email": "buckley+test@fizzus.com",
  "gender": "male",
  "height_cm": "179",
  "height_ft": "",
  "height_in": "",
  "meat[]": ["pork", "fish", "chicken", "beef", "bacon"],
  "name": "buck",
  "physical_activity": "1",
  "products[]": ["egg", " nuts", "cheese", "butter", "coconut"],
  "system": ["metric", "metrics"],
  "target_weight_kg": "84",
  "target_weight_lb": "",
  "typical_day": "1",
  "veggies[]": ["broccoli", "mushrooms", "zucchini", "cauliflower", "avocado"],
  "vip_access": "n",
  "w eight_kg": "95",
  "weight_lb": ""
}
*/
export function getAnswerByQuestionName(name, quiz) {
  return _.filter(_.find(quiz, { name }).answers, {
    selected: true
  }).map(answer => answer.value);
}

export function v1Adapter(quiz, measurements, customerInfo) {
  let { email, fullName: name } = customerInfo;
  let gender = getAnswerByQuestionName('gender', quiz)[0];
  let meat = getAnswerByQuestionName('meat', quiz);
  let veggies = getAnswerByQuestionName('veggies', quiz);
  let products = getAnswerByQuestionName('products', quiz);
  let typicalDay = getAnswerByQuestionName('typical_day', quiz)[0];
  let physical_activity = getAnswerByQuestionName('physical_activity', quiz)[0];
  let sanitizedMeasurements = _.reduce(
    measurements,
    (memo, item, key) => {
      if (item > 0 || key === 'system') {
        memo[key] = item;
      }

      return memo;
    },
    {}
  );
  let v1Req = {
    ...sanitizedMeasurements,
    name,
    email,
    gender,
    meat,
    veggies,
    products,
    typical_day: typicalDay,
    // TODO: hook this up
    vip_access: 'n',
    physical_activity
  };

  return v1Req;
}
