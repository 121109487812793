window.dataLayer = window.dataLayer || [];
const dataLayer = window.dataLayer;

export function dataLayerPush(event, maxTimes) {
  if (process.env.NODE_ENV !== 'production') {
    console.log('gtm event', event);
  }
  dataLayer.push(event);
}

let lastPageView = null;
export function gtmPageView() {
  // this is to prevent the duplicate event sending on initial load
  // TODO: find out why the route is hit multiple times on initial load
  if (lastPageView === window.location.pathname) return;
  lastPageView = window.location.pathname;

  dataLayerPush({ event: 'Pageview', pagePath: window.location.pathname });
}
