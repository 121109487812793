import React, { Component } from 'react';
import StepProgressStatic from './StepProgressStatic';
import history from '../../history';
import ButtonLink from '../ButtonLink';
import isNumeric from 'validator/lib/isNumeric';
import classNames from 'classnames';

class MeasurementsQuestion extends Component {
  constructor(props) {
    super(props);
    this.state.isMobile = this.isMobile();
  }

  state = {
    errors: [],
    measurementsErrors: [],
    isMobile: false
  };

  ageInput = React.createRef();

  // Imperal
  heightFtInput = React.createRef();
  heightInInput = React.createRef();
  weightLbInput = React.createRef();
  targetWeightLbInput = React.createRef();

  // Metric
  heightCmInput = React.createRef();
  weightKgInput = React.createRef();
  targetWeightKgInput = React.createRef();

  isMobile = () => {
    return /Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  };

  findError = error => {
    return error;
  };

  handleSystemChange = async changeEvent => {
    const newSystem = changeEvent.target.value;
    const weightAdjustment = 2.20462;
    const heightAdjustment = 2.54;
    const {
      height_ft,
      height_in,
      weight_lb,
      target_weight_lb,
      height_cm,
      weight_kg,
      target_weight_kg
    } = this.props.measurements;

    if (newSystem === 'imperial') {
      // FIXME: declare vars at the top of the scope for visibility
      // TODO: add a lint rule for this

      // convert from metric to imperial
      var imperialWeight = Math.round(weight_kg * weightAdjustment);
      imperialWeight = imperialWeight === 0 ? '' : imperialWeight;
      await this.props.setMeasurementsProp(
        'weight_lb',
        imperialWeight.toString()
      );
      var imperialTargetWeight = Math.round(
        target_weight_kg * weightAdjustment
      );
      imperialTargetWeight =
        imperialTargetWeight === 0 ? '' : imperialTargetWeight;
      await this.props.setMeasurementsProp(
        'target_weight_lb',
        imperialTargetWeight.toString()
      );
      const convertedInches =
        Math.round(height_cm / heightAdjustment) || '';
      const convertedFeet = Math.floor(convertedInches / 12) || '';
      await this.props.setMeasurementsProp(
        'height_ft',
        convertedFeet.toString()
      );
      await this.props.setMeasurementsProp(
        'height_in',
        convertedInches.toString()
      );
    } else {
      // from imperial to metric
      var metricWeight = Math.round(weight_lb / weightAdjustment);
      metricWeight = metricWeight === 0 ? '' : metricWeight;
      await this.props.setMeasurementsProp(
        'weight_kg',
        metricWeight.toString()
      );
      var metricTargetWeight = Math.round(target_weight_lb / weightAdjustment);
      metricTargetWeight = metricTargetWeight === 0 ? '' : metricTargetWeight;
      await this.props.setMeasurementsProp(
        'target_weight_kg',
        metricTargetWeight.toString()
      );
      const inches = parseInt(height_ft * 12) + parseInt(height_in);
      var convertedCm = Math.round(inches * heightAdjustment);
      if (!convertedCm) {
        convertedCm = '';
      }
      await this.props.setMeasurementsProp('height_cm', convertedCm.toString());
    }
    this.props.setMeasurementsProp('system', newSystem);
  };

  smoothScrollTo = element => {
    const offsetTop = element.getBoundingClientRect().top;
    const scrollTop =
      (window.pageYOffset || document.body.scrollTop) -
      (document.body.clientTop || 0);
    const absolutePosition = offsetTop + scrollTop;

    window.scroll({
      behavior: 'smooth',
      left: 0,
      top: absolutePosition - 79
    });
  };

  scrollTo = (measurementSystem, thisId) => {
    if (measurementSystem === 'imperial') {
      switch (thisId) {
        case 'age':
          this.smoothScrollTo(this.heightFtInput.current);
          break;
        case 'height_ft':
          this.smoothScrollTo(this.heightInInput.current);
          break;
        case 'height_in':
          this.smoothScrollTo(this.weightLbInput.current);
          break;
        case 'weight_lb':
          this.smoothScrollTo(this.targetWeightLbInput.current);
          break;
        default:
          return false;
      }
    } else {
      switch (thisId) {
        case 'age':
          this.smoothScrollTo(this.heightCmInput.current);
          break;
        case 'height_cm':
          this.smoothScrollTo(this.weightKgInput.current);
          break;
        case 'weight_kg':
          this.smoothScrollTo(this.targetWeightKgInput.current);
          break;
        default:
          return false;
      }
    }
  };

  updateMeasurementsErrors = value => {
    this.setState(prevState => ({
      // update measurementsErrors
      measurementsErrors: {
        ...prevState.measurementsErrors, // keep all other key-value pairs
        [value]: undefined // update the value of specific key
      }
    }));
  };

  handleKeyUp = event => {
    const thisId = event.target.id;
    this.updateMeasurementsErrors(thisId);
  };

  handleOnFocus = event => {
    const thisId = event.target.id;
    const measurementSystem = this.props.measurements.system;

    if (this.state.isMobile) {
      this.scrollTo(measurementSystem, thisId);
    }
  };

  isNotNumber = value => {
    if (!isNumeric(value, { no_symbols: true })) {
      return true;
    }
  };

  handleOnChange = event => {
    const thisId = event.target.id;
    const thisValue = event.target.value;
    const theValue = this.isNotNumber(thisValue) ? '' : thisValue;

    this.props.setMeasurementsProp(thisId, theValue);
  };

  handleSubmitMeasurements = event => {
    const {
      age,
      system,
      height_ft,
      height_in,
      weight_lb,
      target_weight_lb,
      height_cm,
      weight_kg,
      target_weight_kg
    } = this.props.measurements;

    var newErrors = {};

    if (age < 14 || this.isNotNumber(age)) {
      newErrors.age = 'please enter an age greater than 14.';
    }
    var continueToCalculating = false;
    // REVIEW: document what `-1` means semantically
    if (system === 'imperial') {
      if (height_ft < 4 || this.isNotNumber(height_ft)) {
        newErrors.height_ft = 'please enter an height greater than 4 ft.';
      } else if (height_ft > 7) {
        newErrors.height_ft =
          'please enter an height less than or equal than 7 ft.';
      }
      if (height_in < 0 || this.isNotNumber(height_in)) {
        newErrors.height_in = 'please enter a height inches.';
      } else if (height_in > 11) {
        newErrors.height_in =
          'please enter a height inches less than or equal to 11 in.';
      }

      if (weight_lb < 70 || this.isNotNumber(weight_lb)) {
        newErrors.weight_lb = 'please enter weight greather than 70 lbs.';
      } else if (weight_lb > 450) {
        newErrors.weight_lb =
          'please enter a weight less than or equal to 450 lbs.';
      }

      if (target_weight_lb < 70 || this.isNotNumber(target_weight_lb)) {
        newErrors.target_weight_lb =
          'please enter weight greather than 70 lbs.';
      } else if (target_weight_lb > 450) {
        newErrors.target_weight_lb =
          'please enter a weight less than or equal to 450 lbs.';
      }
      continueToCalculating = !(
        newErrors.height_ft ||
        newErrors.height_in ||
        newErrors.weight_lb ||
        newErrors.target_weight_lb
      );
    } else {
      if (height_cm < 100 || this.isNotNumber(height_cm)) {
        newErrors.height_cm = 'please enter height greather than 100 cm.';
      } else if (height_cm > 250) {
        newErrors.height_cm =
          'please enter a height less than or equal to 250 cm.';
      }

      if (weight_kg < 31 || this.isNotNumber(weight_kg)) {
        newErrors.weight_kg = 'please enter weight greather than 31 kg.';
      } else if (weight_kg > 204) {
        newErrors.weight_kg =
          'please enter a weight less than or equal to 204 kg.';
      }

      if (target_weight_kg < 31 || this.isNotNumber(target_weight_kg)) {
        newErrors.target_weight_kg = 'please enter weight greather than 31 kg.';
      } else if (target_weight_kg > 204) {
        newErrors.target_weight_kg =
          'please enter a weight less than or equal to 204 kg.';
      }

      continueToCalculating = !(
        newErrors.height_cm ||
        newErrors.weight_kg ||
        newErrors.target_weight_kg
      );
    }
    event.preventDefault();
    this.setState({ measurementsErrors: newErrors });

    // TODO: the next state should not be hard coded and should be configurable
    if (continueToCalculating) {
      history.push('/calculating');
    }
  };

  render() {
    const { measurements } = this.props;
    const { measurementsErrors } = this.state;

    return (
      <div className="quiz-page">
        <div className="step-measurement">
          <StepProgressStatic step={7} />
          <form onSubmit={this.handleSubmitMeasurements}>
            <section className="step-container">
              <div className="container">
                <h2 className="center pt-3 pt-md-0">Your Measurements</h2>
                <div className="row justify-content-center">
                  <div className="col-12 col-sm-10 col-md-10 col-lg-6 mt-3">
                    <div className="measurement-option">
                      <label>
                        <input
                          type="radio"
                          className="system"
                          id="system-imperial"
                          name="system"
                          placeholder="System"
                          value="imperial"
                          checked={measurements.system === 'imperial'}
                          onChange={this.handleSystemChange}
                        />{' '}
                        <span>Imperial (US)</span>
                      </label>
                      <label>
                        <input
                          type="radio"
                          className="system"
                          id="system-metric"
                          name="system"
                          placeholder="System"
                          value="metric"
                          checked={measurements.system === 'metric'}
                          onChange={this.handleSystemChange}
                        />{' '}
                        <span>Metric (Non-US)</span>
                      </label>
                    </div>
                    <div className="form-label-group">
                      <div
                        className={classNames('input-group mb-2', {
                          input_has_error: measurementsErrors.age
                        })}
                        id="age_input"
                      >
                        <div className="input-group-prepend">
                          <div className="input-group-text border-right-0">
                            <i className="far fa-calendar-alt" />
                          </div>
                        </div>
                        <input
                          type="tel"
                          className="form-control numeric border-left-0 border-right-0"
                          id="age"
                          name="age"
                          placeholder="age"
                          value={measurements.age}
                          onChange={this.handleOnChange}
                          maxLength="2"
                          data-min-value="14"
                          data-max-value="99"
                          style={{ paddingLeft: '36px' }}
                          // autoFocus
                          onKeyUp={this.handleKeyUp}
                          onFocus={this.handleOnFocus}
                          ref={this.ageInput}
                        />
                        <label htmlFor="age">age</label>
                        <div className="input-group-prepend">
                          <div className="input-group-text border-left-0 text-muted">
                            years
                          </div>
                        </div>
                      </div>
                      <span
                        className={classNames('span_error', {
                          'has-error': measurementsErrors.age
                        })}
                        id="span_error_age"
                      >
                        {measurementsErrors.age}
                      </span>
                    </div>

                    <div
                      className={classNames('span_imperial', {
                        'd-none': measurements.system !== 'imperial'
                      })}
                    >
                      <div className="row">
                        <div className="col-sm-6 col-6 pr-1">
                          <div className="form-label-group">
                            <div
                              className={classNames('input-group mb-2', {
                                input_has_error: measurementsErrors.height_ft
                              })}
                              id="height_ft_input"
                            >
                              <div className="input-group-prepend">
                                <div className="input-group-text border-right-0">
                                  <i className="fas fa-arrows-alt-v" />
                                </div>
                              </div>
                              <input
                                type="tel"
                                className={
                                  'form-control numeric border-left-0 border-right-0'
                                }
                                id="height_ft"
                                name="height_ft"
                                placeholder="height"
                                value={measurements.height_ft}
                                onChange={this.handleOnChange}
                                maxLength="1"
                                data-min-value="4"
                                data-max-value="7"
                                onKeyUp={this.handleKeyUp}
                                onFocus={this.handleOnFocus}
                                ref={this.heightFtInput}
                              />
                              <label htmlFor="height_ft">height</label>
                              <div className="input-group-prepend">
                                <div className="input-group-text border-left-0 text-muted">
                                  ft
                                </div>
                              </div>
                            </div>
                            <span
                              style={{
                                display:
                                  measurementsErrors.height_ft !== undefined
                                    ? 'block'
                                    : 'none'
                              }}
                              className="span_error"
                              id="span_error_height_ft"
                            >
                              {measurementsErrors.height_ft}
                            </span>
                          </div>
                        </div>
                        <div className="col-sm-6 col-6 pl-1">
                          <div className="form-label-group">
                            <div
                              className={classNames('input-group mb-2', {
                                input_has_error: measurementsErrors.height_in
                              })}
                              id="height_in_input"
                            >
                              <div className="input-group-prepend">
                                <div className="input-group-text border-right-0">
                                  <i className="fas fa-arrows-alt-v" />
                                </div>
                              </div>
                              <input
                                type="tel"
                                className="form-control numeric border-left-0 border-right-0"
                                id="height_in"
                                name="height_in"
                                placeholder="height"
                                value={measurements.height_in}
                                onChange={this.handleOnChange}
                                maxLength="2"
                                data-min-value="0"
                                data-max-value="11"
                                onKeyUp={this.handleKeyUp}
                                onFocus={this.handleOnFocus}
                                ref={this.heightInInput}
                              />
                              <label htmlFor="height_in">height</label>
                              <div className="input-group-prepend">
                                <div className="input-group-text border-left-0 text-muted">
                                  in
                                </div>
                              </div>
                            </div>
                            <span
                              style={{
                                display:
                                  measurementsErrors.height_in !== undefined
                                    ? 'block'
                                    : 'none'
                              }}
                              className="span_error"
                              id="span_error_height_in"
                            >
                              {measurementsErrors.height_in}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-label-group">
                            <div
                              className={classNames('input-group mb-2', {
                                input_has_error: measurementsErrors.weight_lb
                              })}
                              id="weight_lb_input"
                            >
                              <div className="input-group-prepend">
                                <div className="input-group-text border-right-0">
                                  <i className="fa fa-balance-scale" />
                                </div>
                              </div>
                              <input
                                type="tel"
                                className="form-control numeric border-left-0 border-right-0"
                                id="weight_lb"
                                name="weight_lb"
                                placeholder="current weight"
                                value={measurements.weight_lb}
                                onChange={this.handleOnChange}
                                maxLength="3"
                                data-min-value="70"
                                data-max-value="450"
                                onKeyUp={this.handleKeyUp}
                                onFocus={this.handleOnFocus}
                                ref={this.weightLbInput}
                              />
                              <label htmlFor="weight_lb">current weight</label>
                              <div className="input-group-prepend">
                                <div className="input-group-text border-left-0 text-muted">
                                  lbs
                                </div>
                              </div>
                            </div>
                            <span
                              className={classNames('span_error', {
                                'has-error': measurementsErrors.weight_lb
                              })}
                              id="span_error_weight_lb"
                            >
                              {measurementsErrors.weight_lb}
                            </span>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-label-group">
                            <div
                              className={classNames('input-group mb-2', {
                                input_has_error:
                                  measurementsErrors.target_weight_lb
                              })}
                              id="target_weight_lb_input"
                            >
                              <div className="input-group-prepend">
                                <div className="input-group-text border-right-0">
                                  <i className="fa fa-bullseye" />
                                </div>
                              </div>
                              <input
                                type="tel"
                                className="form-control numeric border-left-0 border-right-0"
                                id="target_weight_lb"
                                name="target_weight_lb"
                                value={measurements.target_weight_lb}
                                onChange={this.handleOnChange}
                                maxLength="3"
                                data-min-value="70"
                                data-max-value="450"
                                onKeyUp={this.handleKeyUp}
                                onFocus={this.handleOnFocus}
                                ref={this.targetWeightLbInput}
                                placeholder="goal weight"
                              />
                              <label htmlFor="target_weight_lb">
                                goal weight
                              </label>
                              <div className="input-group-prepend">
                                <div className="input-group-text border-left-0 text-muted">
                                  lbs
                                </div>
                              </div>
                            </div>
                            <span
                              className={classNames('span_error', {
                                'has-error': measurementsErrors.target_weight_lb
                              })}
                              id="span_error_target_weight_lb"
                            >
                              {measurementsErrors.target_weight_lb}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={classNames('span_metric', {
                        'd-none': measurements.system !== 'metric'
                      })}
                    >
                      <div className="form-label-group">
                        <div
                          className={classNames('input-group mb-2', {
                            input_has_error: measurementsErrors.height_cm
                          })}
                          id="height_cm_input"
                        >
                          <div className="input-group-prepend">
                            <div className="input-group-text border-right-0">
                              <i className="fas fa-arrows-alt-v" />
                            </div>
                          </div>
                          <input
                            type="tel"
                            className="form-control numeric border-left-0 border-right-0"
                            id="height_cm"
                            name="height_cm"
                            placeholder="height"
                            value={measurements.height_cm}
                            onChange={this.handleOnChange}
                            maxLength="3"
                            data-min-value="100"
                            data-max-value="250"
                            onKeyUp={this.handleKeyUp}
                            onFocus={this.handleOnFocus}
                            ref={this.heightCmInput}
                          />
                          <label htmlFor="height_cm">height</label>
                          <div className="input-group-prepend">
                            <div className="input-group-text border-left-0 text-muted">
                              cm
                            </div>
                          </div>
                        </div>
                        <span
                          className={classNames('span_error', {
                            'has-error': measurementsErrors.height_cm
                          })}
                          id="span_error_height_cm"
                        >
                          {measurementsErrors.height_cm}
                        </span>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-label-group">
                            <div
                              className={classNames('input-group mb-2', {
                                input_has_error: measurementsErrors.weight_kg
                              })}
                              id="weight_kg_input"
                            >
                              <div className="input-group-prepend">
                                <div className="input-group-text border-right-0">
                                  <i className="fa fa-balance-scale" />
                                </div>
                              </div>
                              <input
                                type="tel"
                                className="form-control numeric border-left-0 border-right-0"
                                id="weight_kg"
                                name="weight_kg"
                                placeholder="current weight"
                                value={measurements.weight_kg}
                                onChange={this.handleOnChange}
                                maxLength="3"
                                data-min-value="31"
                                data-max-value="204"
                                onKeyUp={this.handleKeyUp}
                                onFocus={this.handleOnFocus}
                                ref={this.weightKgInput}
                              />
                              <label htmlFor="weight_kg">current weight</label>
                              <div className="input-group-prepend">
                                <div className="input-group-text border-left-0 text-muted">
                                  kg
                                </div>
                              </div>
                            </div>
                            <span
                              className={classNames('span_error', {
                                'has-error': measurementsErrors.weight_kg
                              })}
                              id="span_error_weight_kg"
                            >
                              {measurementsErrors.weight_kg}
                            </span>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-label-group">
                            <div
                              className={classNames('input-group mb-2', {
                                input_has_error:
                                  measurementsErrors.target_weight_kg
                              })}
                              id="target_weight_kg_input"
                            >
                              <div className="input-group-prepend">
                                <div className="input-group-text border-right-0">
                                  <i className="fa fa-bullseye" />
                                </div>
                              </div>
                              <input
                                type="tel"
                                className="form-control numeric border-left-0 border-right-0"
                                id="target_weight_kg"
                                name="target_weight_kg"
                                value={measurements.target_weight_kg}
                                onChange={this.handleOnChange}
                                maxLength="3"
                                data-min-value="31"
                                data-max-value="204"
                                onKeyUp={this.handleKeyUp}
                                onFocus={this.handleOnFocus}
                                ref={this.targetWeightKgInput}
                                placeholder="goal weight"
                              />
                              <label htmlFor="target_weight_kg">
                                goal weight
                              </label>
                              <div className="input-group-prepend">
                                <div className="input-group-text border-left-0 text-muted">
                                  kg
                                </div>
                              </div>
                            </div>
                            <span
                              className={classNames('span_error', {
                                'has-error': measurementsErrors.target_weight_kg
                              })}
                              id="span_error_target_weight_kg"
                            >
                              {measurementsErrors.target_weight_kg}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section id="section_step_progress_bottom">
              <section className="section-steps">
                <div className="container step-progress-button show-btn">
                  <div className="row no-gutters justify-content-between">
                    <div className="col col-back">
                      <ButtonLink
                        onClick={() => {
                          history.push('/quiz/6');
                        }}
                        className="btn btn-block back-btn"
                      >
                        Go back
                      </ButtonLink>
                    </div>
                    <div className="col col-next">
                      <input
                        type="submit"
                        value="Get My Results!"
                        className="btn btn-block submit_metrics_btn"
                      />
                    </div>
                  </div>
                </div>
              </section>
            </section>
          </form>
        </div>
      </div>
    );
  }
}

export default MeasurementsQuestion;
