import React from 'react';

import Header from '../components/Header';
import TermsCopy from '../components/TermsCopy';
import LetsConnect from '../components/LetsConnect';
import Footer from '../components/Footer';

export default function TermsPage() {
  return (
    <div className="start-quiz-page">
      <Header />
      <section class="step-container">
        <div class="container">
          <div class="row mb-5">
            <div class="col">
              <h2 class="font-weight-bold title-blue">
                Terms &amp; Conditions
              </h2>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-12">
              <TermsCopy />
            </div>
          </div>
        </div>
      </section>
      <LetsConnect />
      <Footer hideSocial />
    </div>
  );
}
