import React, { Component } from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import { Modal, Overlay, Tooltip, Button } from 'react-bootstrap';
import Cookies from 'js-cookie';
import copy from 'copy-to-clipboard';
import _ from 'lodash';

import { dataLayerPush } from '../lib/gtm';

import OfferBox from '../components/offerBox/OfferBox';
import OfferBoxList from '../components/offerBox/OfferBoxList';
import Header from '../components/Header';
import Footer from '../components/Footer';
import TermsCopy from '../components/TermsCopy';
import LetsConnect from '../components/LetsConnect';
import ButtonLink from '../components/ButtonLink';
import PageLoader from '../components/PageLoader';
import PageError from '../components/PageError';
import isEmail from 'validator/lib/isEmail';
import { updateEmail } from '../lib/api_service';

var crypto = require('crypto');

// TODO: separate this into separate component
export function VipBox(props) {
  if (!props.show) {
    return null;
  }

  return (
    <tr>
      <td>1</td>
      <td className="text-left">VIP Access Membership</td>
      <td />
      <td className="text-left">
        {props.currencyFormatter.format(props.upsellAmount)}
      </td>
    </tr>
  );
}

VipBox.propTypes = {
  show: propTypes.bool,
  upsellAmount: propTypes.number
};
export default class SuccessPage extends Component {
  state = {
    show: false,
    getSuccessDataAPI: false,
    upsell: true,
    showVIP: true,
    showTooltip: false,
    upsellAmount: 19.99,
    upsellProduct: {
      planPrice: 0.0
    },
    offerDownsell: false,
    updatedEmail: this.props.appState.customer.email,
    emailMsg: null
  };

  sendDataLayerEvent = _.once(() => {
    if (!this.props.appStateUtils.getState('orderUpsell').orderId) {
      return;
    }

    // only if purchased vip
    if (this.props.appStateUtils.getState('dataLayerEvents').purchase_vip) {
      return;
    }
    this.props.appStateUtils.mergeState('dataLayerEvents', {
      purchase_vip: true
    });
    dataLayerPush({ event: 'purchase_vip' });
  });

  componentDidMount() {
    this.sendDataLayerEvent();
    this.setCookies();
  }

  copyLinkButton = React.createRef();

  modalClose = () => {
    this.setState({ show: false });
  };

  modalShow = () => {
    this.setState({ show: true });
  };

  updateTotalSale = total => {
    this.setState({ totalSale: total });
  };

  setCookies() {
    // set cookies
    const { order, orderUpsell, customer } = this.props.appState;
    // cookies from order
    let cookieOptions = { expires: 30 };
    Cookies.set('mprice', order.amount, cookieOptions);
    Cookies.set('mprice_num', order.amount, cookieOptions);
    Cookies.set('modid', order.orderId, cookieOptions);
    Cookies.set('mname', customer.fullName, cookieOptions);
    if (orderUpsell && orderUpsell.amount !== null) {
      Cookies.set('muprice', orderUpsell.amount, cookieOptions);
      Cookies.set('muprice_num', orderUpsell.amount, cookieOptions);
    }
  }

  updateEmailChange = event => {
    this.setState({ updatedEmail: event.target.value });
  };

  updateMyEmail = async () => {
    if (!isEmail(this.state.updatedEmail, { allow_utf8_local_part: false })) {
      this.setState({ emailMsg: 'Invalid Email Address' });
    } else {
      if (
        this.props.appState.customer.email.toLowerCase() ===
        this.state.updatedEmail.toLowerCase()
      ) {
        this.setState({ emailMsg: 'The email you entered is the same.' });
      } else {
        const updateResult = await updateEmail(
          {
            email: this.state.updatedEmail,
            order_id: this.props.appState.order.orderId
          },
          this.props.sessionToken
        ).catch(err => {
          this.setState({ emailMsg: 'Unknown server error' });
        });

        if (updateResult.status === 204) {
          this.setState({
            emailMsg: 'Email Address Updated'
          });
          // set email cookies
          var sha512Hasher = crypto.createHash('sha512');
          const hashedEmail = sha512Hasher
            .update(this.state.updatedEmail)
            .digest('hex');
          let cookieOptions = { expires: 30 };
          Cookies.set('memail', this.state.updatedEmail, cookieOptions);
          Cookies.set('mhemail', hashedEmail, cookieOptions);
        }
      }
    }
  };

  render() {
    const { order, orderUpsell, customer, locale } = this.props.appState;
    const { language, currencyCode } = locale;
    const currencyFormatter = new Intl.NumberFormat(language, {
      style: 'currency',
      currency: currencyCode
    });
    let totalSale = order.amount + orderUpsell.amount;

    if (!order.orderId) {
      return (
        <React.Fragment>
          <PageLoader isLoaded={this.state.loaded} />
          <PageError>{this.state.errors.map(error => error.message)}</PageError>
        </React.Fragment>
      );
    }

    // TODO: @refactor This needs to live in its own component
    var upsellHtml =
      this.state.offerDownsell && orderUpsell.orderId === null ? (
        <div className="special" id="section_upsell">
          <OfferBox
            ribbonText="Offer"
            title="Your Personal Coach:"
            promoTextPrice={`Get Personal Coaching For Only ${currencyFormatter.format(
              this.state.upsellAmount
            )}/mo`}
          >
            <OfferBoxList text="Unlimited Access To Your Personal Coach Via Email" />
            <OfferBoxList text="Weekly Scheduled Consultations" />
            <OfferBoxList text="Will Provide Customized Weight Loss Tips & Tricks" />
            <OfferBoxList text="Access To Your Coach ANYWHERE, at ANYTIME!" />
          </OfferBox>
          <div className="buttons text-center">
            <h3 className="mt-4 text-normal">
              Would you like to add this to your order?
            </h3>
            <div className="row">
              <label className="checkbox_container d-flex align-items-center justify-content-center text-uppercase mt-4 small mb-4">
                <input
                  type="checkbox"
                  className="option_input"
                  onChange={upsellAmount => {
                    var totalSale = !this.state.upsell
                      ? this.state.price + this.state.upsellAmount
                      : this.state.price;

                    this.setState({
                      upsell: !this.state.upsell,
                      totalSale
                    });
                  }}
                  defaultChecked={this.state.upsell}
                />
                <span className="checkmark" />
                <span style={{ fontSize: '70%' }}>
                  I agree to the recurring&nbsp;
                  <ButtonLink
                    className="btn-text-link text-uppercase px-0"
                    onClick={this.modalShow}
                  >
                    Terms
                  </ButtonLink>
                </span>
              </label>
            </div>
            <button
              className={classNames('btn', 'big-btn', {
                'cursor-not-allowed': !this.state.upsell
              })}
              disabled={!this.state.upsell}
            >
              <span>Click Here To Get This Offer</span>Add To My Order
            </button>
            <br />
            <p className="mt-3 text-muted terms-copy">
              By clicking the button above you consent to our charging your
              payment method $19 and on a 30-day recurring basis from the date
              of purchase without requiring your prior approval for each
              recurring charge, until you decide to cancel. You can cancel at
              anytime by contacting us at support@macrofare.com. After you
              cancel, you will NEVER be billed again. No hassle, no questions
              asked.
            </p>
            <hr />
            <ButtonLink className="btn-text d-inline-block no_btn">
              NO THANK YOU
            </ButtonLink>
          </div>
        </div>
      ) : (
        ''
      );
    let mealplan_url = 'generating mealplan url';
    try {
      mealplan_url = `${process.env.REACT_APP_PORTAL_URL}/mealplan/${
        this.props.appState.order.slug
      }`;
    } catch (error) {}

    return (
      <div className="start-quiz-page">
        <Header />
        <section className="my-5 success">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-sm-10 col-md-10 col-lg-8 col-xl-6">
                <h1>
                  Thank You!&nbsp;
                  <span>Your Meal Plan Is Ready.</span>
                </h1>
                <br />
                <div className="box-details lg">
                  <span className="box-header">Your Order ID Number is:</span>
                  <span className="box-number">{order.orderId}</span>
                </div>
                {/* <!-- .box-details --> */}
                <div id="p_meal_plan_url">
                  <p className="border p-3">
                    <span className="d-block font-weight-bold text-center text-uppercase">
                      Your meal plan link:
                      <Button
                        className="ml-1 btn btn-success btn-sm font-weight-bold"
                        ref={this.copyLinkButton}
                        onClick={() =>
                          this.setState(
                            {
                              showTooltip: true
                            },
                            () =>
                              setTimeout(() => {
                                this.setState({
                                  showTooltip: false
                                });
                                this.copyLinkButton.current.blur();
                              }, 3000),
                            copy(mealplan_url)
                          )
                        }
                      >
                        <i className="fa fa-clipboard" aria-hidden="true" />{' '}
                        Copy Link
                      </Button>
                      <Overlay
                        target={this.copyLinkButton.current}
                        show={this.state.showTooltip}
                        placement="top"
                      >
                        <Tooltip id="overlay-example">
                          <strong>Mealplan Link</strong>
                          <br />
                          Copied to clipboard.
                        </Tooltip>
                      </Overlay>
                    </span>
                    <a
                      className="d-block text-truncate"
                      href={mealplan_url}
                      // eslint-disable-next-line react/jsx-no-target-blank
                      target="_blank"
                    >
                      {mealplan_url}
                    </a>
                  </p>

                  <a
                    className="btn btn-lg home-btn-inverted start-plan mt-3 mb-5"
                    href={mealplan_url}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <span
                      className="font-weight-bold text-uppercase text"
                      style={{ fontSize: '1.5rem' }}
                    >
                      View My Meal Plan
                    </span>
                  </a>
                </div>
                {/* <!-- #p_meal_plan_url --> */}
                <p className="font-weight-bold">
                  *PLEASE BOOKMARK OR SAVE THE LINK TO THIS PAGE:
                </p>
                <p>
                  Your order receipt will be sent to&nbsp;
                  <strong>{customer.email}</strong>
                  &nbsp; in the next 5 minutes. Please double check the email to
                  verify it is correct.
                </p>
                <p>
                  Also add support@macrofare.com to your safe sender list in
                  case it is mistakenly marked as SPAM.
                </p>
                <p>
                  We recommend bookmarking this page or saving the url for your
                  records. Your meal plan link will also be available here when
                  it is ready.
                </p>
                <p>
                  If you have any questions or need assistance, one of our team
                  members will be more than happy to assist you! Please e-mail
                  us at&nbsp;
                  <a href="mailto:support@macrofare.com">
                    support@macrofare.com
                  </a>
                </p>
                <div
                  className="text-center p-5 div_loader_validation"
                  style={{ display: 'none' }}
                >
                  <div className="loading" />
                  <small>Verifying Email Address ...</small>
                </div>
                <div
                  className="alert alert-success div_success_validation"
                  style={{ display: 'none' }}
                >
                  <span className="fa fa-check" />
                  &nbsp;
                  <strong>Email Address Verified!</strong>
                </div>
                <div
                  className="alert alert-danger div_error_validation"
                  style={{ display: 'none' }}
                >
                  <span className="fa fa-warning" />
                  <strong> Email Error:</strong>
                  <span className="span_error_validation_text">
                    Please verify your Email Address.
                  </span>
                </div>
                <div className="box-details email lg">
                  <span className="box-header">
                    <p>
                      <strong>
                        The email you have provided is: {customer.email}
                      </strong>
                    </p>
                    A valid email address must be provided. We use email to
                    communicate with you for order confirmation and tracking
                    information.
                    <br />
                    <strong>
                      Please edit your email address here if it is not accurate:
                    </strong>
                  </span>
                  <div className="form-group div_form">
                    <input
                      type="email"
                      className="form-control input-lg"
                      id="email"
                      name="email"
                      onChange={this.updateEmailChange}
                      value={this.state.updatedEmail}
                    />
                    <span
                      className="span_error"
                      id="span_error_email"
                      style={{
                        display: this.state.emailMsg ? 'block' : 'none'
                      }}
                    >
                      {this.state.emailMsg}
                    </span>
                    <button
                      type="submit"
                      className="home-btn submit_btn"
                      onClick={this.updateMyEmail}
                    >
                      <span className="text">Submit Email</span>
                    </button>
                    <button
                      type="submit"
                      className="home-btn submit2_btn"
                      style={{ display: 'none' }}
                    >
                      <span className="text">Proceed</span>
                    </button>
                  </div>
                  <div
                    className="alert alert-green div_success_alert"
                    style={{ display: 'none' }}
                  >
                    <div className="header">
                      <span className="fa fa-check" />
                      SUCCESS
                    </div>
                    <p>
                      Your meal plan will be sent to your new email{' '}
                      <span id="span_new_email" />.
                    </p>
                  </div>
                </div>
                {upsellHtml}
                <hr className="my-4" />
                <div className="box-details lg table-responsive">
                  <table className="table table-sm-small mb-0 text-dark">
                    <thead className="box-header">
                      <tr>
                        <th className="border-top-0">QTY</th>
                        <th className="border-top-0 text-left">DESCRIPTION</th>
                        <th className="border-top-0" />
                        <th className="border-top-0 text-left">PRICE</th>
                      </tr>
                    </thead>
                    <tbody id="tbody_items">
                      <tr id="tr_item_1">
                        <td>1</td>
                        <td className="text-left">Personalized meal planner</td>
                        <td />
                        <td className="text-left">
                          {currencyFormatter.format(order.amount)}
                        </td>
                      </tr>
                      <VipBox
                        show={orderUpsell.orderId !== null}
                        upsellAmount={orderUpsell.amount}
                        currencyFormatter={currencyFormatter}
                      />
                      <tr>
                        <td />
                        <td />
                        <td className="text-right">Tax:</td>
                        <td className="text-left">$0.00</td>
                      </tr>
                      <tr>
                        <td className="border-top-0 py-0" />
                        <td className="border-top-0 py-0" />
                        <td className="text-right border-top-0 py-0">
                          Shipping:
                        </td>
                        <td className="text-left border-top-0 py-0">$0.00</td>
                      </tr>
                      <tr>
                        <td className="border-top-0" />
                        <td className="border-top-0" />
                        <td className="text-right border-top-0">Total:</td>
                        <td className="text-left border-top-0" id="td_total">
                          {currencyFormatter.format(totalSale)}
                          {/* ${this.state.totalSale} */}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {/* <!-- .box-details --> */}
              </div>
            </div>
          </div>
          {/* <!-- .container --> */}
        </section>
        <LetsConnect />
        <Footer hideSocial />

        {/* <!-- Terms Modal --> */}
        <Modal
          show={this.state.show}
          onHide={this.modalClose}
          dialogClassName="modal-lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Terms & Condition</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <TermsCopy />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
