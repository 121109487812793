import React, { Component } from 'react';
import { Form, Button, Dropdown } from 'react-bootstrap';

import '../assets/stylesheets/pages/_contact.scss';

import Header from '../components/Header';
import LetsConnect from '../components/LetsConnect';
import Footer from '../components/Footer';

const problems = [
  'I have a question about my meal plan.',
  'There is an issue with my meal plan.',
  "I didn't receive my meal plan.",
  'Other'
];
export default class ContactPage extends Component {
  state = {
    selectedProblem: null,
    form: {
      name: '',
      email: '',
      emailVerify: '',
      message: ''
    },
    emailFails: false
  };

  handleProblems = event => {
    this.setState({ selectedProblem: event });
  };

  onSubmit = event => {
    const form = event.currentTarget;
    const emailFails =
      this.state.form.email.toLowerCase() !==
      this.state.form.emailVerify.toLowerCase();
    // REVIEW ALFIE: I removed 'emailFails' on `if (!form.checkValidity() || emailFails)`
    // Not necessary since we only need one email field to solve the issue of having two email field
    // I didn't remove all related syntax yet, let me know then.
    if (!form.checkValidity()) {
      event.preventDefault();
      event.stopPropagation();
      this.setState({
        emailFails
      });
    }
    this.setState({
      validated: true
    });
  };

  changeName = event => {
    var form = { ...this.state.form };
    form.name = event.target.value;
    this.setState({ form });
  };

  changeEmail = event => {
    var form = { ...this.state.form };
    form.email = event.target.value;
    this.setState({ form });
  };

  changeEmailVerify = event => {
    var form = { ...this.state.form };
    form.emailVerify = event.target.value;
    this.setState({ form });
  };

  changeMessage = event => {
    var form = { ...this.state.form };
    form.message = event.target.value;
    this.setState({ form });
  };

  render() {
    var selectDisplay = 'Please select...';
    if (this.state.selectedProblem !== null) {
      selectDisplay = problems[this.state.selectedProblem];
    }

    var emailFailsDiv = <div />;

    if (this.state.emailFails) {
      emailFailsDiv = <div className="input_has_error">Emails must match.</div>;
    }
    return (
      <React.Fragment>
        <Header />
        <section className="contact">
          <div className="container">
            <div className="row pb-0 my-5 my-md-0 p-md-5 justify-content-center text-center">
              <div className="col-md-10 col-lg-8">
                <h3 className="center">
                  Have a question? Try visiting our <a href="/faq">FAQ</a> page.
                </h3>
                <h3 className="center">
                  Otherwise, please feel free to contact us using the form
                  below:
                </h3>
                <p className="center">
                  Our office is open Monday-Friday from 10AM-6PM PST. We are
                  closed on weekends and most holidays. Inquiries sent during
                  non-business hours will be received and you can expect a
                  response on the following business day.
                </p>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-8 col-lg-6 col-xl-5">
                <h2>How Can We Help?</h2>
                <Form
                  validated={this.state.validated}
                  onSubmit={e => this.onSubmit(e)}
                >
                  <Dropdown
                    onSelect={eventKey => {
                      this.handleProblems(parseInt(eventKey));
                    }}
                    className="as-select"
                  >
                    <Dropdown.Toggle
                      className="form-control"
                      id="dropdown-basic"
                    >
                      {selectDisplay}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {problems.map((problem, index) => {
                        return (
                          <Dropdown.Item key={index} eventKey={index}>
                            {problem}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                  <Form.Group controlId="formName">
                    <Form.Control
                      type="text"
                      placeholder="Your name"
                      onChange={this.changeName}
                      value={this.state.form.name}
                    />
                  </Form.Group>
                  <Form.Group controlId="formEmail">
                    <Form.Control
                      type="email"
                      placeholder="Email"
                      onChange={this.changeEmail}
                      value={this.state.form.email}
                      required
                    />
                  </Form.Group>
                  {/* ALFIE's NOTE: I just comment this out, maybe @Will has a purpose on adding this? */}
                  {/* <Form.Group controlId="formEmailVerify">
                    <Form.Control
                      type="email"
                      placeholder="Email"
                      onChange={this.changeEmailVerify}
                      value={this.state.form.emailVerify}
                      isValid={
                        this.state.form.email === this.state.form.emailVerify
                      }
                      required
                    />
                  </Form.Group> */}
                  <Form.Group controlId="formMessage">
                    <Form.Control
                      as="textarea"
                      rows="2"
                      placeholder="Message"
                      onChange={this.changeMessage}
                      value={this.state.form.message}
                      required
                    />
                  </Form.Group>
                  {emailFailsDiv}
                  <Button
                    className="btn btn-lg btn-primary home-btn-inverted start-plan mt-5 send_btn"
                    type="submit"
                  >
                    Send
                  </Button>
                </Form>
                <div className="row justify-content-center text-left text-sm-center py-5 px-2 px-sm-0 contact-widgets">
                  <div className="col-12 col-sm-5 mb-3">
                    <i
                      className="fa fa-phone-square align-middle mr-2"
                      aria-hidden="true"
                    />
                    <span className="align-middle d-inline-block text-left text">
                      <span className="d-block c-label">Phone:</span>
                      <strong>877-925-8009</strong>
                    </span>
                  </div>
                  <div className="col-12 col-sm-7">
                    <i
                      className="fa fa-envelope align-middle mr-2"
                      aria-hidden="true"
                    />
                    <span className="align-middle d-inline-block text-left text">
                      <span className="d-block c-label">Email:</span>
                      <strong>
                        <a href="mailto:support@macrofare.com">
                          support@macrofare.com
                        </a>
                      </strong>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <LetsConnect />
        <Footer />
      </React.Fragment>
    );
  }
}
