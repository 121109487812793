import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import '../assets/stylesheets/components/_pageLoader.scss';
import { ReactComponent as Logo } from '../assets/images/logo_color.svg';

export default function PageLoader({ loadingMessage, isLoaded }) {
  return (
    <div
      className={classNames('page-loader', {
        loaded: isLoaded
      })}
    >
      <div>
        <div className="mb-3">
          <Logo />
        </div>
        <br />
        <div className="sk-circle">
          <div className="sk-circle1 sk-child" />
          <div className="sk-circle2 sk-child" />
          <div className="sk-circle3 sk-child" />
          <div className="sk-circle4 sk-child" />
          <div className="sk-circle5 sk-child" />
          <div className="sk-circle6 sk-child" />
          <div className="sk-circle7 sk-child" />
          <div className="sk-circle8 sk-child" />
          <div className="sk-circle9 sk-child" />
          <div className="sk-circle10 sk-child" />
          <div className="sk-circle11 sk-child" />
          <div className="sk-circle12 sk-child" />
        </div>
        {loadingMessage ? <p className="mt-3 small">{loadingMessage}</p> : null}
      </div>
    </div>
  );
}

PageLoader.propTypes = {
  loadingMessage: PropTypes.string,
  isLoaded: PropTypes.bool
};
