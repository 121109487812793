import React, { Component } from 'react';
import _ from 'lodash';
import history from '../history';

import { ReactComponent as QuizIcon } from '../assets/images/icon/quiz.svg';
import { ReactComponent as AppIcon } from '../assets/images/icon/app.svg';
import { ReactComponent as ScaleIcon } from '../assets/images/icon/scale.svg';
import classNames from 'classnames';

import Header from '../components/Header';
import Footer from '../components/Footer';
import QuizContext from '../store/QuizContext';

export default class SelectGenderPage extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.buttonHandler = this.buttonHandlerFactory();
  }

  state = {
    isMobile: false,
    isClickedMale: false,
    isClickedFemale: false
  };

  isMobile = () => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      this.setState({ isMobile: true });
    }
  };

  buttonHandlerFactory() {
    let handler = () => {};
    if (this.isMobile()) {
      handler = this.handleMobileClick;
    }
    return handler;
  }

  handleMobileClick = actionButton => {
    switch (actionButton) {
      case 'female':
        this.femaleButtonHandler();
        break;
      default:
        this.ButtonHandler();
        break;
    }
  };

  femaleButtonHandler = () => {
    this.setState(
      {
        isClickedFemale: true
      },
      () =>
        setTimeout(() => {
          if (this._isMounted) {
            this.setState({
              isClickedFemale: false
            });
          }
        }, 100)
    );
  };

  ButtonHandler = () => {
    this.setState(
      {
        isClickedMale: true
      },
      () =>
        setTimeout(() => {
          if (this._isMounted) {
            this.setState({
              isClickedMale: false
            });
          }
        }, 100)
    );
  };

  componentDidMount = () => {
    // remove body gender class
    this.props.removeGenderBodyClass();
    this._isMounted = true;
  };

  // KWF: remove this deprecated lifecycle hook
  componentWillUnmount = () => {
    this._isMounted = false;
  };

  selectGender(gender) {
    let genderQuestion = this.quiz[1];

    genderQuestion.answers.forEach(answer => {
      answer.selected = gender === answer.id;
    });

    let quiz = _.cloneDeep(this.quiz);
    quiz[genderQuestion.id] = genderQuestion;
    this.saveToQuizLocal(quiz);
    history.push('/quiz/2');
  }

  render() {
    return (
      <QuizContext.Consumer>
        {({ quiz, saveToQuizLocal }) => {
          this.quiz = quiz;
          this.saveToQuizLocal = saveToQuizLocal;
          return (
            <div className="quiz-page">
              <Header menuDropdown />
              <section className="step-container intro" data-step="1">
                <div className="container" id="take-quiz">
                  <h2 className="font-weight-bold text-uppercase m-0 pt-5">
                    A Personalized Meal Planner
                  </h2>
                  <div className="mt-4 mb-5">
                    <h1 className="font-weight-light">That Works With You</h1>
                    <h1 className="font-weight-bold">And Your Body Type</h1>
                  </div>
                  <h5 className="text-center font-weight-light">
                    Please select your gender:
                  </h5>
                  <div className="row justify-content-center select-gender">
                    <div className="col-12">
                      <button
                        onClick={() => {
                          this.selectGender(1);
                          this.handleMobileClick('male');
                        }}
                        className={classNames(
                          'gender',
                          'male',
                          'single_answer_btn',
                          {
                            clicked: this.state.isClickedMale
                          }
                        )}
                        id="male_btn"
                      >
                        <span className="text">Men start here</span>
                      </button>
                    </div>
                    <div className="col-12">
                      <button
                        onClick={() => {
                          this.selectGender(2);
                          this.handleMobileClick('female');
                        }}
                        className={classNames(
                          'gender',
                          'female',
                          'single_answer_btn',
                          {
                            clicked: this.state.isClickedFemale
                          }
                        )}
                        id="female_btn"
                      >
                        <span className="text">Women start here</span>
                      </button>
                    </div>
                  </div>
                </div>
                {/* <!-- .container --> */}
              </section>
              <section className="how-it-works">
                <div className="container">
                  <h1 className="home-title mb-3" style={{ fontWeight: 200 }}>
                    How it works
                  </h1>
                  <hr />
                  <div className="row d-flex align-items-center justify-content-center">
                    <h1>
                      <span className="blue">1</span>
                    </h1>
                    <div className="col-lg-4 col-md-12 col-sm-12 my-4">
                      <QuizIcon />
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <h1 className="font-weight-light">Take the quiz</h1>
                      <h3>
                        It takes less than 2 minutes! We just need a little bit
                        of info, such as your dietary preferences and current
                        activity levels, to personalize your plan.
                      </h3>
                    </div>
                  </div>
                  <hr />
                  <div className="row d-flex flex-row-reverse align-items-center justify-content-center">
                    <h1>
                      <span className="blue">2</span>
                    </h1>
                    <div className="col-lg-4 col-md-12 col-sm-12 my-4">
                      <ScaleIcon />
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <h1 className="font-weight-light">Set your goal</h1>
                      <h3>
                        Provide some basic measurements so we can determine
                        where you are and where you would eventually like to see
                        yourself.
                      </h3>
                    </div>
                  </div>
                  <hr />
                  <div className="row d-flex align-items-center justify-content-center">
                    <h1>
                      <span className="blue">3</span>
                    </h1>
                    <div className="col-lg-4 col-md-12 col-sm-12 my-4">
                      <AppIcon />
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <h1 className="font-weight-light">Get your results</h1>
                      <h3>
                        You're all set! We've taken care of all the
                        calculations. Take advantage of your macro results and
                        suggested recipes that can help you start achieving a
                        healthier lifestyle today!
                      </h3>
                    </div>
                  </div>
                  <hr />
                  <div className="select-gender mt-5">
                    <a href="#take-quiz" className="gender take-quiz">
                      <span className="text">Get Started Now</span>
                    </a>
                  </div>
                </div>
              </section>

              <Footer hideDisclaimer invertColor />
            </div>
          );
        }}
      </QuizContext.Consumer>
    );
  }
}
