import React, { Component } from 'react';
import Chart from 'chart.js';

class WeightChart extends Component {
  chartRef = React.createRef();

  componentDidMount() {
    const weightChart = this.props.weightChart;
    const system = this.props.system === 'metric'; // else imperial

    const units = system ? 'kg': 'lbs';
    const start_weight = Math.round(system ? weightChart.weightChart.metrics.startWeightKg
    : weightChart.weightChart.metrics.startWeightLbs);
    const end_weight = Math.round(system ? weightChart.weightChart.metrics.endWeightKg :
      weightChart.weightChart.metrics.endWeightLbs);

    const start_point = start_weight + start_weight * 0.1;
    const end_point = end_weight - end_weight * 0.02;
    const min_point = end_weight - end_weight * 0.1;

    const myChartRef = this.chartRef.current.getContext('2d');
    const days = weightChart.weightChart.options.days;
    
    this.chart = new Chart(myChartRef, {
      type: 'line',
      data: {
        backgroundColor: 'rgba(0,0,0,0.0)',
        labels: ['', 'Day 1', 'Day ' + days, ''],
        datasets: [
          {
            label: units,
            backgroundColor: '#08cea3',
            borderColor: '#08cea3',
            pointBorderColor: '#ffffff',
            pointBorderWidth: 2,
            pointBackgroundColor: ['#f75462', '#f75462', '#00ffc8', '#00ffc8'],
            pointRadius: [0, 7, 7, 0],
            data: [start_point, start_weight, end_weight, end_point],
            hover: { mode: null }
          }
        ]
      },
      options: {
        events: [],
        layout: {
          padding: {
            bottom: 0,
            left: -10,
            right: 0,
            top: 0
          }
        },
        legend: {
          display: !1
        },
        tooltips: {
          enabled: !1
        },
        bezierCurve: !0,
        scales: {
          xAxes: [
            {
              position: 'top',
              ticks: {
                beginAtZero: false,
                zeroLineWidth: 0,
                fontFamily: "'Raleway', sans-serif",
                fontColor: '#969696',
                fontSize: '18',
                padding: 24,

                callback: (tick, index) => {
                  if (index !== 1 && index !== 2) {
                    return tick;
                  }
                  return tick;
                }
              },
              gridLines: {
                drawBorder: false
              }
            }
          ],
          yAxes: [
            {
              offset: 0,
              zeroLineWidth: 0,
              gridLines: {
                display: false,
                color: 'rgba(255,255,255,0.0)'
              },
              ticks: {
                beginAtZero: true,
                display: false,
                min: min_point,
                suggestedMin: 0
              }
            }
          ]
        },
        animation: {
          duration: 100,
          onComplete: () => {
            var chart = this.chart,
              ctx = chart.ctx;
            ctx.font = "20px 'Oswald', sans-serif";
            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';
            ctx.fillStyle = '#4d4d4d';

            this.chart.data.datasets.forEach(function(dataset, o) {
              chart.controller
                .getDatasetMeta(o)
                .data.forEach((point, index) => {
                  if (index !== 0 && index !== 3) {
                    if (index === '2') {
                      ctx.fillStyle = '#000';
                    } else {
                      ctx.fillStyle = '#000';
                    }

                    ctx.fillText(
                      Math.floor(dataset.data[index]) + ' ' + units,
                      point._model.x,
                      point._model.y - 15
                    );
                  }
                });
            });
          }
        }
      }
    });
  }
  render() {
    return <canvas id="myChart" ref={this.chartRef} />;
  }
}

export default WeightChart;
