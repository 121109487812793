import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import '../../assets/stylesheets/components/_offerBox.scss';

export default function OfferBox(props) {
  const isVariant = props.variant === 'vip';
  const isTitle = props.title;
  const paddedListClasses = 'mx-auto col-md-10 col-sm-12 px-0 mb-4 pt-5';
  const boldText = 'text-uppercase font-weight-bold';

  return (
    <React.Fragment>
      <div className="vip-package p-3">
        <span className="corner-ribbon top-right">{props.ribbonText}</span>
        {isTitle ? (
          <h3 className="text-normal font-weight-bold">{props.title}</h3>
        ) : null}
        <ul
          className={classNames(
            'list-unstyled',
            isVariant ? paddedListClasses : null
          )}
        >
          {props.children}
        </ul>
        <div className={classNames('offer-box', isVariant ? boldText : null)}>
          {props.promoTextPrice}
        </div>
      </div>
    </React.Fragment>
  );
}

OfferBox.propTypes = {
  ribbonText: PropTypes.string,
  title: PropTypes.string,
  variant: PropTypes.string,
  promoTextPrice: PropTypes.string
};
