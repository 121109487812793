export default {
  system: 'metric',
  age: '',
  height_ft: '',
  height_in: '',
  weight_lb: '',
  target_weight_lb: '',
  height_cm: '',
  weight_kg: '',
  target_weight_kg: ''
};
