import React from 'react';
import { Nav, Navbar, Dropdown, DropdownButton } from 'react-bootstrap';
import { ReactComponent as LogoColor } from '../assets/images/logo_color.svg';
import classNames from 'classnames';
import '../assets/stylesheets/layout/_header.scss';

export default function Header(props) {
  const isMenuDropdown = props.menuDropdown;

  const menuInline = (
    <React.Fragment>
      <Navbar.Toggle
        aria-controls="basic-navbar-nav"
        className="navbar-toggler border border-white"
      />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <Nav.Link href="/#products">Products</Nav.Link>
          <Nav.Link href="/#their-stories">Their Stories</Nav.Link>
          <Nav.Link href="/#our-story">Our Story</Nav.Link>
          <Nav.Link href="/#careers">Careers</Nav.Link>
          <Nav.Link href="/faq">FAQ</Nav.Link>
          <Nav.Link href="https://macrofare.com/hub/">Blog</Nav.Link>
          <Nav.Link href="/contact">Contact Us</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </React.Fragment>
  );

  const menuDropdown = (
    <DropdownButton
      alignRight
      title=""
      id="dropdown-menu-align-right"
      className="menu-dropdown"
    >
      <Dropdown.Item href="/contact">Contact Us</Dropdown.Item>
      <Dropdown.Item href="/faq">FAQ</Dropdown.Item>
      <Dropdown.Item href="https://macrofare.com/hub/">Blog</Dropdown.Item>
      <Dropdown.Item href="https://macrofare.com/hub/careers/">
        Careers
      </Dropdown.Item>
    </DropdownButton>
  );

  return (
    <Navbar
      expand="lg"
      className={classNames(
        'header-nav',
        isMenuDropdown ? 'navbar-menu-dropdown' : 'navbar-menu'
      )}
    >
      <div className="container">
        <Navbar.Brand className="logo" href="/">
          <LogoColor />
        </Navbar.Brand>
        {isMenuDropdown ? menuDropdown : menuInline}
      </div>
    </Navbar>
  );
}
