import React, { Component } from 'react';
import _ from 'lodash';

import history from '../../history';

import { Alert } from 'react-bootstrap';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import ButtonLink from '../ButtonLink';

class Question extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.buttonHandler = this.buttonHandlerFactory();
  }

  state = {
    alertError: false,
    mounted: false,
    isMobile: false,
    isClickedNext: false,
    isClickedBack: false
  };

  componentDidMount = () => {
    // add gender class on body
    this.props.setGenderBodyClass();
    this._isMounted = true;
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  isMobile = () => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      this.setState({ isMobile: true });
    }
  };

  buttonHandlerFactory() {
    let handler = () => {};
    if (this.isMobile()) {
      handler = this.handleMobileClick;
    }
    return handler;
  }

  handleMobileClick = actionButton => {
    switch (actionButton) {
      case 'next':
        this.nextButtonHandler();
        break;
      default:
        this.ButtonHandler();
        break;
    }
  };

  nextButtonHandler = () => {
    this.setState(
      {
        isClickedNext: true
      },
      () =>
        setTimeout(() => {
          if (this._isMounted) {
            this.setState({
              isClickedNext: false
            });
          }
        }, 100)
    );
  };

  ButtonHandler = () => {
    this.setState(
      {
        isClickedBack: true
      },
      () =>
        setTimeout(() => {
          if (this._isMounted) {
            this.setState({
              isClickedBack: false
            });
          }
        }, 100)
    );
  };

  answerById = (QnA, answerId) => {
    return QnA.answers.find(answer => answer.id === answerId);
  };

  selectAnswerSingleChoice = (QnA, answer) => {
    var navigateNext = false;

    QnA.answers.forEach(ans => {
      if (ans.id === answer.id && ans.selected !== true) {
        navigateNext = ans.selected = true;
      } else {
        ans.selected = false;
      }
    });

    let quiz = _.cloneDeep(this.props.quiz);
    quiz[QnA.id] = QnA;

    if (navigateNext) {
      setTimeout(() => {
        if (this._isMounted) {
          this.setState({ isCssTransitionMounted: true }, () =>
            this.setState({ isCssTransitionMounted: false })
          );
        }
      }, 500);
      let currentQuestion = quiz[QnA.id + 1];
      this.props.saveToQuizLocal(quiz);
      history.push('/quiz/' + currentQuestion.id);
      return false;
    } // else
    this.props.saveToQuizLocal(quiz);

    return true;
  };

  selectAnswerMulti = (QnA, answer) => {
    if (answer === null) {
      alert(`Did not find answer in question: ${QnA.question}`);
      return;
    }

    answer.selected = !answer.selected;
    let quiz = _.cloneDeep(this.props.quiz);
    quiz[QnA.id] = QnA;

    this.props.saveToQuizLocal(quiz);
  };

  // returns if error alert
  selectAnswer = (QnA, answerId) => {
    const answer = this.answerById(QnA, answerId);
    if (QnA.type === 'single') {
      return this.selectAnswerSingleChoice(QnA, answer);
    }
    this.selectAnswerMulti(QnA, answer);
    return false;
  };

  // return true if needs error alert
  nextQuestion = QnA => {
    var next = this.props.quiz[QnA.id + 1];
    var readyNext =
      QnA.type === 'multi' || _.some(QnA.answers, { selected: true });

    if (next !== null && next !== undefined && readyNext) {
      setTimeout(() => {
        if (this._isMounted) {
          this.setState({ isCssTransitionMounted: true }, () =>
            this.setState({ isCssTransitionMounted: false })
          );
        }
      }, 100);
      this.props.saveToQuizLocal(this.props.quiz);
      history.push('/quiz/' + next.id);
    } else {
      if (readyNext) {
        setTimeout(() => {
          if (this._isMounted) {
            this.setState(
              {
                isCssTransitionMounted: true
              },
              () => this.setState({ isCssTransitionMounted: false })
            );
          }
        }, 100);
        this.props.saveToQuizLocal(this.props.quiz);
        history.push('/measurements');
      } else {
        return true;
      }
    }
    return false;
  };

  previousQuestion = QnA => {
    let currentQuestion = this.props.quiz[QnA.id - 1];
    this.props.saveToQuizLocal(this.props.quiz);

    setTimeout(() => {
      if (this._isMounted) {
        this.setState({ currentQuestion, isCssTransitionMounted: true }, () =>
          this.setState({ isCssTransitionMounted: false })
        );
      }
    }, 100);
    if (currentQuestion.id === 1) {
      history.push('/gender');
    } else {
      history.push('/quiz/' + currentQuestion.id);
    }
  };

  render() {
    let { currentQuestion, subQuestion } = this.props;

    if (currentQuestion === null) {
      return <div>Loading...</div>;
    } else {
      return (
        <CSSTransition
          in={this.state.isCssTransitionMounted}
          classNames="fade"
          timeout={150}
        >
          <div>
            <section className="step-container">
              <div className="container">
                <h2 className="center pt-3 pt-md-0">
                  {currentQuestion.question}
                </h2>
                {subQuestion ? <h3 className="mb-2">{subQuestion}</h3> : null}
                <div className="row justify-content-center">
                  <div className="col-12 col-sm-10 col-md-10 col-lg-6 mt-3">
                    {currentQuestion.answers.map((answer, i) => (
                      <ButtonLink
                        onClick={() => {
                          this.setState({
                            alertError: this.selectAnswer(
                              currentQuestion,
                              answer.id
                            )
                          });
                        }}
                        // TODO: refactor to use currying of common classnames
                        className={classNames(
                          'btn',
                          'btn-block',
                          'btn-q',
                          'single_selection_btn',
                          {
                            'btn-type-multi': currentQuestion.type === 'multi'
                          },
                          { selected: answer.selected }
                        )}
                        key={i}
                      >
                        {currentQuestion.type === 'multi' ? (
                          <span className="checkbox-text">{answer.text}</span>
                        ) : (
                          answer.text
                        )}
                      </ButtonLink>
                    ))}
                  </div>
                </div>
                <div
                  style={{
                    display: this.state.alertError ? 'block' : 'none'
                  }}
                  className="select-a-choice-alert text-center mt-3"
                >
                  <Alert variant="primary" className="d-inline-block">
                    Please select a choice
                  </Alert>
                </div>
              </div>
            </section>
            <section id="section_step_progress_bottom">
              <section className="section-steps">
                <div className="container step-progress-button show-btn">
                  <div className="row no-gutters justify-content-between">
                    <div
                      className={classNames('col', 'col-back', {
                        clicked: this.state.isClickedBack
                      })}
                    >
                      <ButtonLink
                        onClick={() => {
                          this.previousQuestion(currentQuestion);
                          this.handleMobileClick('back');
                        }}
                        className="btn btn-block back-btn"
                      >
                        Go back
                      </ButtonLink>
                    </div>
                    <div
                      className={classNames('col', 'col-next', {
                        clicked: this.state.isClickedNext,
                        'pulse animated':
                          currentQuestion.id === 2 || currentQuestion.id === 3
                      })}
                    >
                      <ButtonLink
                        onClick={() => {
                          this.setState({
                            alertError: this.nextQuestion(currentQuestion)
                          });
                          this.handleMobileClick('next');
                        }}
                        className="btn btn-block single_next_btn"
                      >
                        Next Question <i className="fa fa-angle-double-right" />
                      </ButtonLink>
                    </div>
                  </div>
                </div>
              </section>
            </section>
          </div>
        </CSSTransition>
      );
    }
  }
}

export default Question;
