import React, { Component } from 'react';
import axios from 'axios';
import * as Sentry from '@sentry/browser';

import MealplanPageV1Wrap from './MealplanPageV1Wrap';

const API_BASE = process.env.REACT_APP_API_URL;
export default class MealPlanPageFactory extends Component {
  state = {
    mealPlanId: null
  };
  componentDidMount() {
    let orderSlug = this.props.match.params.orderSlug;

    axios
      .get(`${API_BASE}/mealplans/${orderSlug}`)
      .then(res => {
        this.setState({
          mealPlanId: res.data.meal_plan.meal_plan_id
        });
      })
      .catch(error => {
        Sentry.captureException(error);
        // TODO error to user
        // We need a universal error msging system.
      });
    axios
      .post(`${API_BASE}/viewedMealplan/`, {
        slug: orderSlug
      })
      .catch(error => {
        Sentry.captureException(error);
      });

    // add class 'overflow-hidden' on <body>
    // to use iframe scroll bar instead
    document.body.classList.add('overflow-hidden');
  }

  componentWillUnmount = () => {
    document.body.classList.remove('overflow-hidden');
  };

  // TODO: need to handle v2 mealplans
  render() {
    return <MealplanPageV1Wrap mealPlanId={this.state.mealPlanId} />;
  }
}
