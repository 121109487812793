import React, { Component } from 'react';
import QuizContext from './QuizContext';
import history from '../history';
import _ from 'lodash';
import axios from 'axios';
import Subject from 'pubsub-js';
import * as Sentry from '@sentry/browser';

import { saveEmail } from '../lib/api_service';
import jsonQuiz from '../data/fixtures/quiz_1';
import jsonMeasurements from '../data/fixtures/measurements';
import { extractAnswers } from '../lib/quizResults';

const API_BASE = process.env.REACT_APP_API_URL;
const MEAL_PLAN_DAYS = 28;

var countryList = require('../data/fixtures/CountryList.json');

class QuizProvider extends Component {
  constructor(props) {
    super(props);

    Subject.subscribe('email:collect', this.handleCollectEmail);

    this.state = {
      locale: {},
      customer: {},
      userToken: '',
      quiz: {},
      measurements: {},
      results: {
        success: false
      },
      weightChart: {
        success: false
      },
      countryList,
      order: {},
      orderUpsell: {}
    };

    this.loadState('locale', {
      language: 'en-US',
      system: 'imperial',
      countryCode: 'US',
      currencyCode: 'USD',
      countryName: 'United States',
      fetched: false
    });
    this.loadState('mealplan');
    this.loadState('quiz', jsonQuiz);
    this.loadState('weightChart', 
      {
        success: false
      });
    this.loadState('results', {
      success: false
    });
    this.loadState(
      'measurements',
      Object.assign({}, jsonMeasurements, {
        system: this.state.locale.system
      })
    );
    this.loadState('customer', {
      fullName: '',
      email: ''
    });
    this.loadState('order', {
      paymentStrategy: null,
      orderId: null,
      payToken: null,
      promoCode: null,
      amount: 0.0
    });
    this.loadState('orderUpsell', {
      paymentStrategy: null,
      orderId: null,
      payToken: null,
      promoCode: null,
      amount: 0.0
    });
    this.loadState('dataLayerEvents', {});
  }

  /**
   * Persist the key-value pair to localStorage
   * @param {string} key
   * @param {any} value
   * @return {any} value
   */
  storeState(key, value) {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {}

    return value;
  }

  /**
   * Update the state, and persist the key-value pair to localStorage
   * @param {string} key
   * @param {any} value
   * @return {any} value
   */
  saveState(key, value) {
    this.setState({ [key]: value });
    return this.storeState(key, value);
  }

  /**
   * Load a key-value pair from localStorage and update the state
   * Should be called from a component.constructor()
   * @param {string} key
   * @param {any} defaultValue Use this value if none exist in localStorage
   * @return {any} value
   */
  loadState(key, defaultValue = null) {
    let val;
    try {
      val = JSON.parse(localStorage.getItem(key)) || defaultValue;
    } catch (error) {
      val = defaultValue;
    }
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state[key] = val;
    return this.state[key];
  }

  /**
   * Deep merge a value with an existing value and persist to localStorage
   * @param {string} key
   * @param {any} value
   * @return {value}
   */
  mergeState(key, value) {
    let merged = !_.isObjectLike(this.state[key])
      ? value
      : _.merge({}, this.state[key], value);
    return this.saveState(key, merged);
  }

  /**
   * Return a value from the state
   * If the key is empty, return the whole state
   * @param {string} key
   * @param {any} defaultValue
   */
  getState(key = null, defaultValue = null) {
    if (_.isNil(key)) return this.state;
    return _.get(this.state, key, defaultValue);
  }

  getBrowserLanguage() {
    let language =
      navigator.userLanguage || navigator.language || navigator.browserLanguage;
    if (navigator.languages && navigator.languages.length) {
      language = navigator.languages[0];
    }
    return language;
  }

  componentDidMount() {
    if (!this.state.locale.fetched) {
      this.fetchGeoData();
    }
  }

  saveToQuizLocal = quiz => {
    this.saveState('quiz', quiz);
  };

  questionById = qid => {
    return this.state.quiz[qid];
  };

  clearAnswers = QnA => {
    QnA.answers.forEach(answer => {
      answer.selected = false;
    });
  };

  removeGenderBodyClass = () => {
    const body = document.body;
    body.classList.remove('male-theme', 'female-theme');
  };

  setGenderBodyClass = () => {
    const body = document.body;
    const currentGender = this.loadState('gender');

    switch (currentGender) {
      case 1:
        body.classList.add('male-theme');
        break;
      default:
        body.classList.add('female-theme');
    }
  };

  genderBodyClass = gender => {
    const body = document.body;
    body.classList.remove('male-theme', 'female-theme');
    this.storeState('gender', gender);
    this.setGenderBodyClass();
  };

  selectGender = gender => {
    this.genderBodyClass(gender);
    this.clearAnswers(this.state.quiz[1]);
    this.selectAnswer(this.state.quiz[1], gender);

    // setTimeout a bit to show press button state on mobile
    setTimeout(() => {
      history.push('/quiz/2');
    }, 100);
  };

  setMeasurementsProp = (name, prop) => {
    this.mergeState('measurements', { [name]: prop });
  };

  saveQuiz = () => {
    return axios
      .post(`${API_BASE}/quiz_results`, {
        sessionToken: this.props.sessionToken,
        quizResults: Object.assign(extractAnswers(this.state.quiz), {
          measurements: this.state.measurements
        })
      })
      .then(res => {
        return this.setState({ quizResultsId: res.data.id });
      });
  };

  selectedId(answers) {
    var answer = _.find(answers, { selected: true });
    // TODO: fix this. `_.find` returns `undefined`, not null.
    // '' and 0 will also satisfy this
    if (answer != null) {
      return answer.id;
    }
    return -1;
  }

  // TODO: @refactor Move this code to the email collection page
  handleCollectEmail = (type, message) => {
    const { email } = message;
    this.mergeState('customer', { email });
    saveEmail({ email }, this.props.sessionToken).catch(error => {
      // TODO: insert customer support number
      Sentry.captureException(error);
      history.push('/checkout/email', {
        errors: [
          'Sorry, we had trouble saving your email. Please try again or contact customer support at INSERT NUMBER HERE'
        ]
      });
    });

    history.push('/payment');
  };

  // TODO: refactor this. Weight chart is being created at time of quiz submission
  requestWeightChart = () => {
    // check if calculated/metrics results has been set
    if (!this.state.results.success) {
      return Promise.resolve();
    }

    const { options, metrics } = this.state.results;

    const params = {
      gender: options.gender,
      age: options.age,
      heightCm: options.heightCm,
      weightKg: options.weightKg,
      pal: metrics.pal,
      calorieIntake: metrics.suggestedDailyCalories,
      days: MEAL_PLAN_DAYS // TODO see if this can be generated
    };

    return axios
      .get(process.env.REACT_APP_API_URL + '/metrics/weight_chart', {
        params: params
      })
      .then(res => {
        this.saveState('weightChart', res.data);
      })
      .catch(error => console.error('weight chart:', error));
  };

  requestCalculatedMetricsResults = () => {
    // TODO: make this less brittle by not using index to find the question
    var gender = _.find(this.state.quiz[1].answers, { selected: true });

    // TODO: separate and test the requesting of calculated metrics from the
    // munging of data
    const params = {
      units: this.state.measurements.system,
      gender: gender.value,
      age: this.state.measurements.age,
      physicalActivity: this.selectedId(this.state.quiz[2].answers),
      typicalDay: this.selectedId(this.state.quiz[3].answers)
    };

    if (this.state.measurements.system === 'imperial') {
      params.heightFt = this.state.measurements.height_ft;
      params.heightIn = this.state.measurements.height_in;
      params.weightLbs = this.state.measurements.weight_lb;
      params.goalWeightLbs = this.state.measurements.target_weight_lb;
    } else {
      params.heightCm = this.state.measurements.height_cm;
      params.weightKg = this.state.measurements.weight_kg;
      params.goalWeightKg = this.state.measurements.target_weight_kg;
    }

    return axios.get(`${API_BASE}/metrics/calculate`, { params }).then(res => {
              this.saveState('results', res.data);

      this.setState({ results: res.data }, () => {
        this.storeState('results', res.data);
        this.requestWeightChart();
      });
    });

    // TODO: remove hard coded next state
    // REVIEW: why were we reverting back to the /selectGender on exception?
    // history.push('/gender');
  };

  fetchGeoData = () => {
    return axios.get(`${API_BASE}/geo`).then(res => {
      let language = this.getBrowserLanguage();
      let countryCode = res.data.country_iso || 'US';
      let system = countryCode === 'US' ? 'imperial' : 'metric';
      let { countryName, currencyCode } = _.get(
        this.state.countryList,
        countryCode,
        { countryName: res.data.country_name || '', currencyCode: 'USD' }
      );

      this.mergeState('locale', {
        language,
        system,
        countryCode,
        countryName,
        currencyCode,
        fetched: true
      });
    });
  };

  render() {
    // Bundle app state management functions for export
    // TODO: @refactor Consider using a factory function to export this
    const appStateUtils = {
      setState: this.setState.bind(this),
      storeState: this.storeState.bind(this),
      saveState: this.saveState.bind(this),
      loadState: this.loadState.bind(this),
      mergeState: this.mergeState.bind(this),
      getState: this.getState.bind(this)
    };

    return (
      <QuizContext.Provider
        value={{
          state: this.state,
          sessionToken: this.props.sessionToken,
          questionById: this.questionById,
          removeGenderBodyClass: this.removeGenderBodyClass,
          setGenderBodyClass: this.setGenderBodyClass,
          measurements: this.state.measurements,
          setMeasurementsProp: this.setMeasurementsProp,
          handleSuccessDataAPI: this.handleSuccessDataAPI,
          handlePostDataAPI: this.handlePostDataAPI,
          results: this.state.results,
          requestCalculatedMetricsResults: this
            .requestCalculatedMetricsResults,
          weightChart: this.state.weightChart,
          requestWeightChart: this.requestWeightChart,
          globalTopic: Subject,
          saveQuiz: this.saveQuiz,
          appStateUtils: appStateUtils,
          saveToQuizLocal: this.saveToQuizLocal,
          quiz: this.state.quiz
        }}
      >
        {this.props.children}
      </QuizContext.Provider>
    );
  }
}

export default QuizProvider;
