import React, { Component } from 'react';
import Cookies from 'js-cookie';
import { Form, Button, InputGroup } from 'react-bootstrap';

import QuizContext from '../store/QuizContext';
import Footer from '../components/Footer';
import { dataLayerPush } from '../lib/gtm';

import { ReactComponent as LogoColor } from '../assets/images/logo_color.svg';

import isEmail from 'validator/lib/isEmail';
import classNames from 'classnames';

var crypto =  require('crypto');

export default class CheckoutEmailPage extends Component {
  state = {
    email: this.props.appState.customer.email,
    emailIsValid: true,
    errors: []
  };

  componentDidMount() {
    dataLayerPush({ event: 'addToCart' });
  }

  twoWayBind = stateProperty => {
    return event => {
      this.setState({
        [stateProperty]: event.target.value
      });
    };
  };

  handleEmail = (subject, topic) => {
    return event => {
      event.preventDefault();
      subject.publish(topic, {
        email: this.state.email
      });
      this.setState({
        email: ''
      });
    };
  };

  handleValidation = event => {
    if (
      !isEmail(this.state.email, {
        allow_utf8_local_part: false
      })
    ) {
      event.preventDefault();
      this.setState({ emailIsValid: false });
    } else {
      this.setState({ emailIsValid: true });

      // set email cookies
      var sha512Hasher = crypto.createHash('sha512');
      const hashedEmail = sha512Hasher.update(this.state.email).digest('hex');
      let cookieOptions = { expires: 30 };
      Cookies.set('memail', this.state.email, cookieOptions);
      Cookies.set('mhemail', hashedEmail, cookieOptions);
    }
  };

  render() {
    return (
      <QuizContext.Consumer>
        {({ globalTopic }) => {
          return (
            <div className="quiz-page">
              <section className="banner inner checkout">
                <div className="container">
                  <div className="col mt-2 py-3">
                    <LogoColor />
                  </div>
                  <h3 className="text-center mb-2">
                    Secure your personalized plan
                  </h3>
                  <p className="text-center banner-sub-text pb-4 mb-0">
                    You're one step away from getting on the path to a healthier
                    you. Provide your e-mail so we know where to send your plan.
                  </p>
                </div>
              </section>
              <section className="step-container shipping">
                <div className="container">
                  <h3 className="text-center mb-3">
                    Tell us where to send <br />
                    <span>your personalized plan:</span>
                  </h3>
                  <div className="row justify-content-center">
                    <div className="col-12 col-sm-10 col-md-10 col-lg-6">
                      <Form
                        onSubmit={this.handleEmail(
                          globalTopic,
                          'email:collect'
                        )}
                      >
                        <Form.Group>
                          <InputGroup
                            className={classNames('mb-2', {
                              input_has_error: !this.state.emailIsValid
                            })}
                          >
                            <InputGroup.Prepend>
                              <InputGroup.Text id="inputGroupPrepend">
                                <i className="far fa-envelope" />
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                              type="email"
                              placeholder="Enter your e-mail here..."
                              onChange={this.twoWayBind('email')}
                              value={this.state.email}
                            />
                            <InputGroup.Prepend>
                              <InputGroup.Text id="inputGroupPrepend">
                                <i className="fa fa-envelope-o invisible" />
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                          </InputGroup>
                          <span
                            className={classNames('span_error', {
                              'has-error': !this.state.emailIsValid
                            })}
                          >
                            Invalid Email Address
                          </span>
                        </Form.Group>
                        <div className="row justify-content-center button-wrap">
                          <div className="col-md-10 col-md-4">
                            <Button
                              className="button-styled w-100 btn-lg question payment-btn"
                              type="submit"
                              onClick={this.handleValidation}
                            >
                              Last Step
                              <i className="fa fa-angle-double-right" />
                            </Button>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </section>
              <Footer hideDisclaimer invertColor />
            </div>
          );
        }}
      </QuizContext.Consumer>
    );
  }
}
