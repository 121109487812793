import productData from '../data/fixtures/ProductData.json';
import _ from 'lodash';

// TODO: @feature Implement promo code support.
export function getProductData(category, countryCode = 'US', offerVersion = 1) {
  let query = { category, countryCode, offerVersion };
  let result =
    _.find(productData, query) ||
    _.find(productData, {
      category,
      countryCode: '*',
      offerVersion
    });

  if (!result) {
    throw new Error('Unable to find a valid product configuration.');
  }

  return { ...result, countryCode };
}
