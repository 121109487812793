import React, { Component } from 'react';

export default class LetsConnect extends Component {
  render() {
    return (
      <section className="take-steps">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-12 col-md-5 col-sm-12">
              <h1>
                Let's <span>connect</span>
              </h1>
              <h3 className="mb-0">
                <a href="mailto:support@macrofare.com">support@macrofare.com</a>
              </h3>
              <h5 className="font-weight-bold">Customer support</h5>
              <h3 className="mb-0">
                <a href="mailto:brand@macrofare.com">brand@macrofare.com</a>
              </h3>
              <h5 className="font-weight-bold">Partnership inquiries</h5>
              <h3 className="mb-0">
                <a href="mailto:press@macrofare.com">press@macrofare.com</a>
              </h3>
              <h5 className="font-weight-bold">Media inquiries</h5>
            </div>
            <div className="col-12 col-md-7 col-sm-12">
              <ul className="social-media social-icons mb-0">
                <li className="connected-icon">
                  <a
                    target="_blank"
                    href="https://facebook.com/macrofare"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-facebook" />
                    <h5>Facebook</h5>
                  </a>
                </li>
                <li className="connected-icon">
                  <a
                    target="_blank"
                    href="https://pinterest.com/macrofare"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-pinterest" />
                    <h5>Pinterest</h5>
                  </a>
                </li>
                <li className="connected-icon">
                  <a
                    target="_blank"
                    href="https://instagram.com/macrofareplan"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-instagram" />
                    <h5>Instagram</h5>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
