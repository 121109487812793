import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as CheckboxIcon } from '../../assets/images/icon/checkbox.svg';

export default function OfferBoxList({ text }) {
  return (
    <li className="list-item">
      <CheckboxIcon /> {text}
    </li>
  );
}

OfferBoxList.propTypes = {
  text: PropTypes.string
};
