// npm modules

import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import axios from 'axios';

import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Helmet } from 'react-helmet';

import Cookies from 'js-cookie';

import { captureException, Sentry } from './lib/errorHandling';
// Pages
import SelectGenderPage from './pages/SelectGenderPage';
import PaymentPage from './pages/PaymentPage';
import StartQuizPage from './pages/StartQuizPage';
import FinalPage from './pages/FinalPage';
import SuccessPage from './pages/SuccessPage';
import MealPlanProvider from './store/MealPlanProvider';
import MealPlanPageFactory from './pages/MealPlanPageFactory';
import ContactPage from './pages/ContactPage';
import CheckoutEmailPage from './pages/CheckoutEmailPage';
import SpecialPage from './pages/SpecialPage';
import FaqPage from './pages/FaqPage';
import TermsPage from './pages/TermsPage';
import DisclaimerPage from './pages/DisclaimerPage';
import VersionPage from './pages/VersionPage';
import QuizContext from './store/QuizContext';
import QuizProvider from './store/QuizProvider';
import Quiz from './components/quiz/Quiz';
import Calculating from './components/quiz/Calculating';
import MeasurementsQuestion from './components/quiz/MeasurementsQuestion';
import history from './history.js';
import ScrollToTop from './components/ScrollToTop';
import { gtmPageView } from './lib/gtm';

const API_URL = process.env.REACT_APP_API_URL;
class App extends Component {
  state = {
    sessionToken: null
  };

  constructor(props) {
    super(props);
    this.getSessionRetryCount = 0;

    this.getSessionToken().then(sessionToken => {
      this.setState({ sessionToken });
      axios.defaults.headers.common['Authorization'] = sessionToken;
    });
  }

  getSessionToken = () => {
    let location = window.location;
    let sessionToken = Cookies.get('sessionToken');
    if (sessionToken !== undefined && sessionToken !== 'undefined') {
      return Promise.resolve(sessionToken);
    }

    return axios
      .post(`${API_URL}/user_session`, {
        location
      })
      .then(result => {
        sessionToken = result.data.sessionToken;
        let cookieOptions = { expires: 14 };
        if (process.env.REACT_APP_DOMAIN) {
          Object.assign(cookieOptions, {
            domain: process.env.REACT_APP_DOMAIN
          });
        }
        if (sessionToken) {
          Cookies.set('sessionToken', sessionToken, cookieOptions);
          Sentry.setExtra('sessionToken', sessionToken);
        }

        return sessionToken;
      })
      .catch(error => {
        if (this.getSessionRetryCount >= 5) {
          captureException(error);
          return;
        }
        this.getSessionRetryCount = this.getSessionRetryCount + 1;
        setTimeout(this.getSessionToken, 200);
      });
  };

  render() {
    const metaRobots = (
      <Helmet>
        <meta name="robots" content="none, noarchive" />
      </Helmet>
    );

    const metaRobotsAndPin = (
      <Helmet>
        <meta name="robots" content="none, noarchive" />
        <meta name="pinterest" content="nopin" />
      </Helmet>
    );

    return (
      <QuizProvider sessionToken={this.state.sessionToken}>
        <div className="App" style={{ height: '100vh' }}>
          <Router history={history}>
            <Route
              path="/"
              render={() => {
                gtmPageView();
              }}
            />
            {process.env.NODE_ENV !== 'production' ? (
              <Route
                path="/clear"
                render={({ history }) => {
                  localStorage.clear();
                  Cookies.remove('sessionToken');
                  history.replace('/');
                  window.location.reload();
                  return <h1>tada</h1>;
                }}
              />
            ) : (
              ''
            )}

            <QuizContext.Consumer>
              {({
                state,
                sessionToken,
                appStateUtils,
                setGenderBodyClass,
                removeGenderBodyClass,
                measurements,
                measurementsErrors,
                updateMeasurementsErrors,
                setMeasurementsProp,
                handleSubmitMeasurements,
                quiz,
                questionById
              }) => {
                return (
                  <Route
                    render={({ location }) => (
                      <ScrollToTop>
                        <TransitionGroup component={null}>
                          <CSSTransition
                            key={location.key}
                            classNames="fade"
                            timeout={150}
                          >
                            <Switch location={location}>
                              <Route
                                path="/"
                                exact
                                render={props => <StartQuizPage {...props} />}
                              />
                              <Route
                                path="/gender"
                                render={props => (
                                  <SelectGenderPage
                                    removeGenderBodyClass={
                                      removeGenderBodyClass
                                    }
                                  />
                                )}
                              />
                              <Route
                                path="/quiz/:questionId"
                                render={props => (
                                  <React.Fragment>
                                    {metaRobots}
                                    <Quiz
                                      questionById={questionById}
                                      appState={state}
                                      {...props}
                                    />
                                  </React.Fragment>
                                )}
                              />
                              <Route
                                path="/measurements"
                                render={props => (
                                  <React.Fragment>
                                    {metaRobots}
                                    <MeasurementsQuestion
                                      measurements={measurements}
                                      measurementsErrors={measurementsErrors}
                                      updateMeasurementsErrors={
                                        updateMeasurementsErrors
                                      }
                                      setMeasurementsProp={setMeasurementsProp}
                                      handleSubmitMeasurements={
                                        handleSubmitMeasurements
                                      }
                                      quiz={quiz}
                                    />
                                  </React.Fragment>
                                )}
                              />
                              <Route
                                path="/calculating"
                                render={props => (
                                  <React.Fragment>
                                    {metaRobots}
                                    <Calculating
                                      messageTime={[
                                        {
                                          time: 500,
                                          msg: 'Loading...'
                                        },
                                        {
                                          time: 375,
                                          msg: 'Calculating your results...'
                                        },
                                        {
                                          time: 375,
                                          msg: 'Building your meal plan...'
                                        },
                                        {
                                          time: 375,
                                          msg: 'Almost complete...'
                                        },
                                        {
                                          time: 375,
                                          msg: "It's ready!"
                                        }
                                      ]}
                                    />
                                  </React.Fragment>
                                )}
                              />
                              <Route
                                path="/checkout/email"
                                render={props => (
                                  <React.Fragment>
                                    {metaRobots}
                                    <CheckoutEmailPage
                                      appState={state}
                                      {...{
                                        appStateUtils
                                      }}
                                      {...props}
                                    />
                                  </React.Fragment>
                                )}
                              />
                              <Route
                                path="/final"
                                render={props => (
                                  <React.Fragment>
                                    {metaRobots}
                                    <FinalPage
                                      setGenderBodyClass={setGenderBodyClass}
                                      appState={state}
                                      {...{
                                        appStateUtils
                                      }}
                                    />
                                  </React.Fragment>
                                )}
                              />
                              <Route
                                path="/payment"
                                render={props => (
                                  <React.Fragment>
                                    {metaRobotsAndPin}
                                    <PaymentPage
                                      appState={state}
                                      {...{
                                        sessionToken,
                                        appStateUtils
                                      }}
                                      userbase="156,000"
                                      mealPlanWeekLength="28"
                                      {...props}
                                    />
                                  </React.Fragment>
                                )}
                              />

                              <Route
                                path="/success"
                                render={props => {
                                  return (
                                    <React.Fragment>
                                      {metaRobotsAndPin}
                                      <SuccessPage
                                        appState={state}
                                        {...{
                                          sessionToken,
                                          appStateUtils
                                        }}
                                        {...props}
                                      />
                                    </React.Fragment>
                                  );
                                }}
                              />

                              {/* static pages */}
                              <Route
                                path="/contact"
                                render={props => <ContactPage {...props} />}
                              />
                              <Route
                                path="/special"
                                render={props => {
                                  return (
                                    <React.Fragment>
                                      {metaRobotsAndPin}
                                      <SpecialPage
                                        appState={state}
                                        {...{
                                          sessionToken,
                                          appStateUtils
                                        }}
                                        {...props}
                                      />
                                    </React.Fragment>
                                  );
                                }}
                              />
                              <Route
                                path="/faq"
                                render={props => <FaqPage {...props} />}
                              />
                              <Route
                                path="/terms"
                                render={props => <TermsPage {...props} />}
                              />
                              <Route
                                path="/disclaimer"
                                render={props => <DisclaimerPage {...props} />}
                              />
                              {/* /static pages */}

                              {/* MealPlanProvider route needs to be below other routes. */}
                              <MealPlanProvider
                                sessionToken={this.state.sessionToken}
                              >
                                <Route
                                  path="/mealplan/:orderSlug"
                                  render={props => (
                                    <React.Fragment>
                                      {metaRobotsAndPin}
                                      <MealPlanPageFactory
                                        appState={state}
                                        {...props}
                                      />
                                    </React.Fragment>
                                  )}
                                />
                              </MealPlanProvider>
                            </Switch>
                          </CSSTransition>
                        </TransitionGroup>
                      </ScrollToTop>
                    )}
                  />
                );
              }}
            </QuizContext.Consumer>

            <Route path="/version" component={VersionPage} />
          </Router>
          <QuizContext.Consumer>
            {({ state }) => {
              var debug = '';
              if (
                process.env.NODE_ENV === 'development' &&
                this.state.debug === true
              ) {
                debug = (
                  <div
                    style={{
                      position: 'absolute',
                      top: '2px',
                      left: 0,
                      width: '300px',
                      height: '3em',
                      background: '#fff',
                      color: 'red'
                    }}
                  >
                    <div>Debug:</div>
                    <code>{JSON.stringify(state.debugInfo)}</code>
                  </div>
                );
              }
              return <React.Fragment>{debug}</React.Fragment>;
            }}
          </QuizContext.Consumer>
        </div>
      </QuizProvider>
    );
  }
}

export default App;
