import React from 'react';

export default function MealplanPageV1Wrap(props) {
  // let mealplanId = props.match.params.mealplanId;
  let mealplanId = props.mealPlanId;
  let mpPageSrc = `${
    process.env.REACT_APP_V1_MP_PAGE
  }?personalized=${mealplanId}`;

  return (
    <div style={containerStyle}>
      <iframe
        src={mpPageSrc}
        border="0"
        width="100%"
        height="100%"
        title="&nbsp;"
        style={iframeStyle}
      />
    </div>
  );
}

const containerStyle = {
  height: '100%'
};

const iframeStyle = {
  border: 'none'
};
