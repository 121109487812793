import React, { Component } from 'react';
import { Accordion, Card } from 'react-bootstrap';

import Header from '../components/Header';
import LetsConnect from '../components/LetsConnect';
import Footer from '../components/Footer';

const jsonFaq = {
  questions: [
    {
      id: '1',
      title: 'What is MacroFare?',
      description:
        'We provide a personalized meal planner that consists of suggested recipes, not the actual meal itself or ingredients necessary to prepare the food. The major benefit of using our personalized plans is that every recipe is specifically configured for your macro/calorie requirements and ingredient preferences. While you could use something like a Keto cookbook, you will need to calculate the correct serving sizes for each recipe in order to achieve the results you want.'
    },
    {
      id: '2',
      title: "I place an order but haven't received my recipes?",
      description:
        'You should receive your meal plan within 5 minutes after submitting payment. You may also need to check your junk folder as it may have been mistakenly marked as SPAM. If you have still not received your meal plan, please message us using the contact form on our website and provide the e-mail you used to purchase. We will gladly update your email and resend your order receipt.'
    },
    {
      id: '3',
      title:
        "I received my recipes, why does it include things I didn't select?",
      description:
        'We will try to provide you with a meal plan that fits your dietary selections as best as possible. However, if not enough items are selected it may be possible that you receive recipes that include ingredients outside of your preferences. If you find that recipes in your meal plan will be an issue for you (allergies, dietary restriction, etc), please contact us and we will do our best to accommodate any of your requests.'
    },
    {
      id: '4',
      title: 'I see a lot of repeat recipes, why is this?',
      description:
        "Our initial goal was to help provide a meal planner that is time and budget friendly (buying ingredients in bulk, prepping portions ahead of time etc), which is the reason for repetition in the recipes. We were receiving a high number of complaints when there were too many recipes, as it wasn't realistic for some customers to be cooking a different option 5 times a day. We found that the current logic being used is a happy medium between the two and helps customers stay on track. If you would like more variation included with your recipes, please contact us and will do our best to accommodate you."
    },
    {
      id: '5',
      title:
        'My recipes look like they include carbs, I thought this was keto-friendly?',
      description:
        'Some of the recipes titles/images do not properly reflect the actual ingredients you will be using. If you check the ingredients, they have either been properly adjusted or omitted so that it does meet the 5% maximum carb allowance for a ketogenic diet. Unless it is a restricted Keto diet, cutting carbs completely is not necessary. We are currently working to update the recipes to avoid any confusion. In the meantime, please ignore any instructions that call for ingredients not in the products section.'
    },
    {
      id: '6',
      title:
        "This is a lot more calories than I'm used to eating, is this right?",
      description:
        "It's important to know that not all calories are built the same. A single gram of fat provides 9 calories of energy whereas a gram of carbs or protein only provides 4 calories. So yes, it may sound like a much higher calorie value than you're used but it's more about eating right instead of eating less."
    },
    {
      id: '7',
      title: 'Can I dine out while following my plan?',
      description:
        "It's perfectly fine to dine out without restrictions. Simply resume you plan wherever you left off. For example: If you were to go on vacation on Friday, it would be fine to eat out the entire weekend and jump back in on Monday. It may take a few days for your body to get back into ketosis but flexibility is one of the great benefits of macro dieting."
    },
    {
      id: '8',
      title: 'What type of beverages can I have while following my recipes?',
      description:
        'Some alternatives to water could be: Sparkling Water, Seltzer, and Club Soda, Coffee and Tea, Diet Soda, and Juice Alternatives (Sugar-Free, Zero Calorie). We would recommend limiting drinks with higher amounts of sugars and carbs. This also goes the same for alcoholic beverages.'
    },
    {
      id: '9',
      title: 'Can I eat fruit while following my recipes?',
      description:
        "We exclude fruit because, in most cases, it can cause an immediate spike in blood sugar. This would immediately halt the fat burning - everything we're trying to avoid on keto. If you do find yourself wanting some fruit, please opt for low glycemic options."
    },
    {
      id: '10',
      title: 'Can I print the recipes from my recipes?',
      description:
        "Unfortunately, we do not offer a printable version at this time. We apologize for any inconvenience this may cause. We recommend saving bookmarks or adding thumbnails to your mobile device's homescreen for quick and easy access."
    },
    {
      id: '11',
      title:
        'Can you provide me with examples of my recipes before I purchase?',
      description:
        'Unfortunately, we are not able to provide examples as every plan is personalized and based on answers from the quiz. If you receive your recipes and see any you do not like, simply contact us and we will do our best to accommodate you.'
    },
    {
      id: '12',
      title: 'What forms of payment do you accept?',
      description:
        'We currently accept major credit/debit cards at this time. We apologize for any inconvenience this may cause. We can assure you that all payments are 100% safe and secure.'
    },
    {
      id: '13',
      title: 'Is this a subscription? Will I get charged monthly?',
      description:
        'There is no subscription billing with our meal planner. You will only be charged a one-time payment and receive lifetime access.'
    }
  ]
};

export default class FaqPage extends Component {
  state = {
    activeId: null
  };

  toggleShow = id => {
    this.setState({ activeId: id });
  };

  render() {
    return (
      <div className="start-quiz-page">
        <Header />
        <section className="mb-5">
          <div className="container">
            <div className="row mb-5">
              <div className="col">
                <h2 className="font-weight-bold title-blue">
                  Frequently Asked Questions
                </h2>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Accordion defaultActiveKey="0" className="accordion-style">
                  {jsonFaq.questions.map((question, i) => (
                    <Card
                      key={`question:${i}`}
                      onClick={() => this.toggleShow(i)}
                      className={i === this.state.activeId ? 'active' : ''}
                    >
                      <Accordion.Toggle as={Card.Header} eventKey={i}>
                        {question.title}
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey={i}>
                        <Card.Body>{question.description}</Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  ))}
                </Accordion>
              </div>
            </div>
          </div>
        </section>
        <LetsConnect />
        <Footer hideSocial />
      </div>
    );
  }
}
