import React, { Component, Fragment } from 'react';
import { Modal } from 'react-bootstrap';

import FeatureCard from '../components/FeatureCard';
import Footer from '../components/Footer';
import PageLoader from '../components/PageLoader';
import PageError from '../components/PageError';

import { ReactComponent as LogoColor } from '../assets/images/logo_color.svg';
import WeightChart from '../components/final/WeightChart';
import history from '../history';

const jsonModalsInfo = {
  bmi: {
    title: 'Body Mass Index (BMI)',
    body: (
      <Fragment>
        <p>
          Body Mass Index (BMI) is a established unit of measure to define
          weight relative to height. The National Institutes of Health (NIH) now
          defines normal weight, overweight, and obesity according to BMI rather
          than the traditional height/weight charts. Overweight is a BMI of 27.3
          or more for women and 27.8 or more for men.
        </p>
        `
      </Fragment>
    )
  },
  waterIntake: {
    title: 'Water Intake (per day)',
    body: (
      <Fragment>
        <p>
          This is the suggested amount of water to drink each day to help keep
          your metabolism running efficiently. The amount of water each
          individual needs varies on a number of factors. Dehydration can affect
          your physical performance - causing tiredness, weakness, and degrading
          endurance. The same can also be said for drinking excessive amounts of
          water, which can create an electrolyte imbalance, causing similar
          effects.
        </p>
        `
      </Fragment>
    )
  },
  caloriesPerDay: {
    title: 'Calories per day',
    body: (
      <Fragment>
        <p>
          The suggested calorie intake can be used within a +/-100 range but
          should not fall below this number. The figure has been calculated
          based on the answers you provided, such as age, weight, height,
          physical activity level, and target weight.{' '}
        </p>
      </Fragment>
    )
  },
  macronutrientRatios: {
    title: 'Macronutrient Ratios',
    body: (
      <Fragment>
        <p>
          Macronutrients (macros for short) are the foods that fuel your body
          the most measured in grams of:{' '}
          <strong>carbohydrates, protein, and fat.</strong> Any macro that isn't
          immediately needed by our body is stored as excess body fat.
        </p>
        <p>
          Your body's primary choice of fuel is carbs. However, you can actually
          help your body use fat as the main source by following a diet with a
          low fat, moderate protein, and high carb restriction. This change in
          metabolic state is known as <strong>ketosis or keto.</strong>
        </p>
      </Fragment>
    )
  },
  milestoneWeigth: {
    title: 'Milestone Weight',
    body: (
      <Fragment>
        <p>
          This is the amount of weight you can safely lose within 30 days of
          starting your plan. This number is suggested and only accounts for
          adjusting your diet to the suggested calories and macronutrient
          ratios.
        </p>
      </Fragment>
    )
  },
  suggestedCalorieIntake: {
    title: 'Suggested calorie intake',
    body: (
      <Fragment>
        <p>
          Suggested calorie intake for target of
          {/*<?php echo round(($_SESSION['system'] == 'metric') ? $_SESSION['target_weight_kg'] : $_SESSION['target_weight_lb']); ?>*/}
          {/*<?php echo ($_SESSION['system'] == 'metric') ? "KGS" : "LBS"; ?>*/}
          .
        </p>
      </Fragment>
    )
  }
};

export default class Final extends Component {
  state = {
    show: false,
    modalTitle: null,
    modalBody: null,
    errors: [],
    weightChart: this.props.appState.weightChart,
    results: this.props.appState.results
  };

  componentDidMount = () => {
    // add gender class on body
    this.props.setGenderBodyClass();
  };

  modalClose = () => {
    this.setState({ show: false });
  };

  modalShow(modalParams, event) {
    event.preventDefault();
    this.setState({
      show: true,
      modalTitle: modalParams.title,
      modalBody: modalParams.body
    });
  }

  goToNext = () => {
    history.push('/checkout/email');
  };

  render() {
    if (!this.state.results.success || !this.state.weightChart.success) {
      return (
        <React.Fragment>
          <PageLoader isLoaded={this.state.loaded} />
          <PageError>{this.state.errors.map(error => error.message)}</PageError>
        </React.Fragment>
      );
    }
    const mealPlanDaysLength = this.state.weightChart.weightChart.options.days;
    var targetWeightValue =
      Math.floor(this.state.results.options.goalWeightKg) + ' kg';
    if (this.state.results.options.units !== 'metric') {
      targetWeightValue =
        Math.floor(this.state.results.options.goalWeightLbs) + ' lbs';
    }

    return (
      <div className="quiz-page">
        <section className="final">
          <div className="container">
            <div className="col-12 col-lg-6 col-md-8 mx-auto px-0">
              <h1 className="mb-3">
                Your&nbsp;
                <span className="highlight-bg">results</span> are in:
              </h1>
              <p>
                We've come up with a personalized recommendation and meal
                planner that can help you with reaching your{' '}
                <strong>goal of {targetWeightValue}</strong>.
              </p>
              <p className="mb-2 mt-5 font-weight-bold">
                Milestone - 28-Day Achievable Weight
              </p>
              <div className="result-container">
                <WeightChart
                  system={this.state.results.options.units}
                  weightChart={this.state.weightChart}
                />
              </div>
              <div className="col-12 px-0 mt-0 mx-auto">
                <div
                  className="row d-flex align-items-stretch no-gutters pt-4"
                  style={{ background: '#00cfa2' }}
                >
                  <div className="col-6 col-md-6 col-sm-6 py-2 current-bg">
                    <h5 className="mb-0 text-uppercase font-weight-bold mt-1">
                      Current BMI:
                    </h5>
                    <p className="mb-0">
                      {this.state.results.metrics.bmi}{' '}
                      <small>({this.state.results.metrics.bmiType})</small>
                    </p>
                  </div>

                  <div className="col-6 col-md-6 col-sm-6 py-2 goal-bg">
                    <h5 className="mb-0 text-uppercase font-weight-bold mt-1">
                      Goal BMI:
                    </h5>
                    <p className="mb-0">
                      {this.state.results.metrics.goalBMI}{' '}
                      <small>({this.state.results.metrics.goalBMIType})</small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="summary">
          <div className="container">
            <div className="row justify-content-center">
              <h2 className="text-center mt-3">
                <span className="text-white">
                  <h1
                    className="text-center"
                    style={{
                      fontWeight: '900',
                      fontSize: '2rem',
                      lineHeight: '2rem'
                    }}
                  >
                    Recommendations
                  </h1>
                  <h3
                    className="text-center mb-0"
                    style={{ fontWeight: '200' }}
                  >
                    (Calories and macronutrient ratios per day for goal of{' '}
                    <span className="text-white">{targetWeightValue}</span>)
                  </h3>
                </span>
              </h2>
            </div>
          </div>
        </section>
        <section className="summary-macro">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-sm-12 col-md-6 col-lg-4 mt-5">
                <div className="item">
                  {/*countUp-1-modal*/}
                  <button
                    className="info-btn"
                    onClick={this.modalShow.bind(
                      this,
                      jsonModalsInfo.caloriesPerDay
                    )}
                  >
                    <i className="far fa-lightbulb" />
                  </button>
                  <div className="top goal">
                    Calorie <span style={{ fontWeight: '300' }}>Intake</span>
                  </div>
                  <div className="middle">
                    <figure className="chart" data-percent="95">
                      <svg viewBox="0 0 260 260">
                        <linearGradient
                          x1="50%"
                          y1="0%"
                          x2="50%"
                          y2="100%"
                          id="linearGradient-4"
                        >
                          <stop stopColor="#9ac709" offset="0%" />
                          <stop stopColor="#ddecad" offset="100%" />
                        </linearGradient>
                        <circle
                          className="outer calories"
                          cx="62"
                          cy="131"
                          r="76"
                          transform="rotate(-90, 95, 95)"
                        />
                        <circle
                          className="outer calories opacity"
                          opacity="0.229966518"
                          cx="62"
                          cy="131"
                          r="76"
                          transform="rotate(-90, 95, 95)"
                        />
                        <text
                          id="25%"
                          fontFamily="Oswald"
                          fontSize="52"
                          fontWeight="300"
                          fill="#000000"
                        >
                          <tspan
                            textAnchor="middle"
                            x="50%"
                            y="145"
                            id="countUp-6"
                          >
                            {this.state.results.metrics.suggestedDailyCalories}
                          </tspan>
                        </text>
                        <text
                          id="25%"
                          fontFamily="Oswald"
                          fontSize="20"
                          fontWeight="300"
                          fill="#000000"
                        >
                          <tspan textAnchor="middle" x="50%" y="175">
                            ± 100 / day
                          </tspan>
                        </text>
                      </svg>
                    </figure>
                  </div>
                  <div className="bottom">
                    <p className="font-weight-bold">*Suggested k/cal per day</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4 mt-5">
                <div className="item">
                  {/*countUp-4-modal*/}
                  <button
                    className="info-btn"
                    onClick={this.modalShow.bind(
                      this,
                      jsonModalsInfo.macronutrientRatios
                    )}
                  >
                    <i className="far fa-lightbulb" />
                  </button>
                  <div className="top goal">
                    Macronutrient{' '}
                    <span style={{ fontWeight: '300' }}>Ratio</span>
                  </div>
                  <div className="middle macros">
                    <div className="progressItems">
                      <div className="progressBar">
                        <div className="header">
                          Carbohydrates -{' '}
                          <span id="countUp-9">
                            {this.state.results.macros.carbsGrams}
                          </span>
                          g
                        </div>
                        <div className="value">
                          {this.state.results.macros.carbsPercent * 100 + '%'}
                        </div>
                        <div className="bar">
                          <div
                            style={{
                              width:
                                this.state.results.macros.carbsPercent * 100 +
                                '%'
                            }}
                          />
                        </div>
                      </div>
                      <div className="progressBar">
                        <div className="header">
                          Proteins -{' '}
                          <span id="countUp-11">
                            {this.state.results.macros.proteinGrams}
                          </span>
                          g
                        </div>
                        <div className="value">
                          {this.state.results.macros.proteinPercent * 100 + '%'}
                        </div>
                        <div className="bar">
                          <div
                            style={{
                              width:
                                this.state.results.macros.proteinPercent * 100 +
                                '%'
                            }}
                          />
                        </div>
                      </div>
                      <div className="progressBar">
                        <div className="header">
                          Fats -{' '}
                          <span id="countUp-10">
                            {this.state.results.macros.fatsGrams}
                          </span>
                          g
                        </div>
                        <div className="value">
                          {this.state.results.macros.fatsPercent * 100 + '%'}
                        </div>
                        <div className="bar">
                          <div
                            style={{
                              width:
                                this.state.results.macros.fatsPercent * 100 +
                                '%'
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bottom">
                    <p className="text-center mt-3 font-weight-bold">
                      *Suggested fat, protein, &amp; carbs
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* TODO: make this reference the state machine to determine next step */}
            {/* TODO: make this <a> tag not refresh the window*/}
            <div
              onClick={this.goToNext}
              className="btn btn-info btn-lg checkout-btn mt-5"
            >
              Get {mealPlanDaysLength}-day{' '}
              <span className="text-yellow">Meal Planner</span>{' '}
              <i className="fas fa-angle-double-right" />
            </div>
          </div>
        </section>
        <section className="graph-descriptions">
          <Modal show={this.state.show} onHide={this.modalClose} centered>
            <Modal.Header closeButton>
              <Modal.Title>{this.state.modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{this.state.modalBody}</Modal.Body>
          </Modal>
        </section>
        <section className="benefits pb-5">
          <div className="container">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h1>
                <span style={{ fontWeight: '800', color: '#FFF' }}>
                  Save yourself the time and stress of finding your "next"
                  diet...
                </span>
              </h1>
              <p className="mb-4 mt-3">
                <span>
                  A personalized meal planner that works with YOU and YOUR body
                  type.
                </span>{' '}
                Includes personalized recipe recommendations that can be a part
                of helping you achieve the lifestyle you want while saving you
                time and money - It's more than just a healthy option!
              </p>
            </div>
          </div>
        </section>
        <section className="skills pb-5">
          <div className="container">
            <div className="intro-logo col py-3">
              <div className="mb-2">
                <LogoColor />
              </div>
              <h3 className="mb-0 text-center">
                Personalized {mealPlanDaysLength}-Day Plan
              </h3>
            </div>
            <h1 className="instant-access center">Get instant access:</h1>
            <div className="row d-flex justify-content-center">
              <FeatureCard
                name="menu"
                title={`${mealPlanDaysLength}-Day Meal Planner`}
                descriptionEmphasis="Your personalized recipes"
                description="Breakfast, lunch,
                    dinner, and snacks. Access anywhere, anytime. Compatible
                    with mobile and desktop devices."
              />
              <FeatureCard
                name="recipe"
                title="Quick &amp; Easy Recipes"
                descriptionEmphasis="Time and budget friendly"
                description="You'll only use
                    ingredients that you know, like, and are easy on your
                    wallet! Fancy cooking skills not required."
              />
            </div>
            <div className="row d-flex justify-content-center">
              <FeatureCard
                name="quiz"
                title="Personalized Touch"
                descriptionEmphasis="Taste and goal preferences"
                description="Recipes are
                    selected for your tastes, while helping to fill your
                    calories and macros. Let's talk about easy."
              />

              <FeatureCard
                name="grocery"
                title="Weekly Grocery Lists"
                descriptionEmphasis="Hassle-free shopping"
                description="Simply download the
                    complete grocery list each week. Categorized by aisle to get
                    you in and out fast."
              />
            </div>
            <a
              href="/checkout/email"
              className="btn btn-info btn-lg checkout-btn mt-4"
            >
              Get It Now - <span className="text-yellow">Instant Access</span>
            </a>
          </div>
        </section>
        <section className="options">
          <div className="container">
            <div className="col-md-9 col-sm-12">
              <h1 className="mb-4 font-weight-bold">
                Easy-to-follow recipes that are chosen specifically for YOU!
              </h1>
              <p>
                It's not another unsustainable diet you've tried in the past...
              </p>
              <p>
                Most programs fall under a "one-size fits all" that's expected
                to work for everyone - and we all know that's never the case.
              </p>
              <p>
                <strong>Every BODY is different</strong> - and diets should be
                too.
              </p>
              <p>
                Let us take care of the meticulous planning that makes your
                plan, YOUR plan.
              </p>
              <p>
                Every recipe comes in simple single servings to help you achieve
                AND sustain long-term results.
              </p>
              <p>
                No more stressing out over exact measurements or trying to
                figure out what you need to eat next either.
              </p>
              <div className="border p-3 my-3">
                <p>
                  Your suggested recipes are designed to help you meet your
                  goals while still allowing for flexibility.
                </p>
                <p>
                  You won't have to deal with awkward dining situations or
                  trying to resist favorite foods!
                </p>
                <p className="mb-0">
                  Remember, we are not dogs. No need to "reward" ourselves with
                  cheat days. When you eat something outside of your plan, don't
                  feel bad about it! Change the way you think about food and it
                  can help you stay on track for the long haul.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section id="get-it-now" className="summary-final">
          <div className="container">
            <p
              className="text-center mb-4"
              style={{ fontWeight: '200', fontSize: '1.3rem' }}
            >
              Your personalized meal plan is ready for immediate use. Just click
              the button below to start making a change today!
            </p>
            <a
              href="/checkout/email"
              className="btn btn-info btn-lg checkout-btn text-white"
              id="addToCart"
            >
              Click Here To <span className="text-yellow">Get Started</span>
            </a>
            <p className="text-center mt-3 font-weight-bold text-uppercase">
              Lifetime &amp; unlimited access to plan
            </p>
          </div>
        </section>

        <Footer hideDisclaimer invertColor />
      </div>
    );
  }
}
