import React, { Fragment, useState } from 'react';
import classNames from 'classnames';
import { Modal } from 'react-bootstrap';
import ButtonLink from '../components/ButtonLink';

import '../assets/stylesheets/layout/_footer.scss';

export default function Footer(props) {
  const today = new Date();
  const isInvertColor = props.invertColor;
  const hideSocial = props.hideSocial;
  const hideDisclaimer = props.hideDisclaimer;

  const privacyLinkCopy =
    'https://www.iubenda.com/privacy-policy/88696491/legal';
  const cookieLinkCopy =
    'https://www.iubenda.com/privacy-policy/88696491/cookie-policy';

  const [showModal, setShowModal] = useState(false);
  const [iframeSrc, setiframeSrc] = useState();

  const footerSocial = (
    <div className="col-12 col-lg-3 ml-auto text-center text-lg-right">
      <a
        className="footer-social mr-3"
        href="https://www.facebook.com/macrofare"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fab fa-facebook" />
      </a>
      <a
        className="footer-social"
        href="https://www.instagram.com/macrofareplan"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fab fa-instagram" />
      </a>
    </div>
  );

  const footerDisclaimer = (
    <Fragment>
      <p className="text-left font-size-13">
        <strong>Disclaimer:</strong> This site cannot/does not contain medical,
        health, fitness, or nutritional advice. The medical, health, fitness,
        and nutritional information is provided for general informational and
        educational purposes only and is not a substitute for professional
        advice.
      </p>
      <p className="text-left font-size-13">
        We encourage you to consult with the appropriate professionals before
        taking any actions based upon such information. The use or reliance of
        any information contained on this site is solely at your own risk.
      </p>
    </Fragment>
  );

  return (
    <Fragment>
      <footer
        className={classNames('footer', 'text-center', 'text-lg-left', {
          'footer-invert': isInvertColor
        })}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-1">
              <ul className="list-unstyled">
                <li>
                  <a href="https://macrofare.com/hub/">Blog</a>
                </li>
                <li>
                  <a href="https://macrofare.com/hub/careers">Careers</a>
                </li>
                {/* <!-- show this on mobile --> */}
                {/* TODO: turn these into links */}
                <li className="d-sm-inline-block d-lg-none">
                  <a href="/faq">FAQ</a>
                </li>
                <li className="d-sm-inline-block d-lg-none">
                  <a href="/contact">Contact Us</a>
                </li>
              </ul>
            </div>
            <div className="col-12 col-lg-3 d-none d-lg-block">
              <ul className="list-unstyled">
                <li>
                  <a href="/faq">FAQ</a>
                </li>
                <li>
                  <a href="/contact">Contact Us</a>
                </li>
              </ul>
            </div>
            {hideSocial ? null : footerSocial}
          </div>
          <div className="w-100 clearfix text-center text-lg-left">
            {hideDisclaimer ? (
              <Fragment>
                <hr />
              </Fragment>
            ) : (
              footerDisclaimer
            )}
            <span className="float-none float-lg-right d-block mt-1 mt-lg-0 text-center second-footer-links">
              <ul className="list-inline mb-0">
                <li className="list-inline-item">
                  <a href="/terms">Terms & Conditions</a>
                </li>
                <li className="list-inline-item">
                  <ButtonLink
                    className="btn-text btn-privacy-link"
                    onClick={() => {
                      setShowModal(true);
                      setiframeSrc(privacyLinkCopy);
                    }}
                  >
                    Privacy Policy
                  </ButtonLink>
                </li>
                <li className="list-inline-item">
                  <ButtonLink
                    className="btn-text btn-cookie-link"
                    onClick={() => {
                      setShowModal(true);
                      setiframeSrc(cookieLinkCopy);
                    }}
                  >
                    Cookie Policy
                  </ButtonLink>
                </li>
                <li className="list-inline-item">
                  <a href="/disclaimer">Disclaimer</a>
                </li>
              </ul>
            </span>
            <span className="d-block text-center text-sm-left d-sm-inline-block mb-3 mb-lg-0">
              &copy;{today.getFullYear()} MacroFare.com. All Rights Reserved.
            </span>
          </div>
        </div>
      </footer>
      <Modal
        id="loadIframeModal"
        show={showModal}
        dialogClassName="modal-lg"
        centered
      >
        <Modal.Body>
          <button
            type="button"
            class="close"
            onClick={() => setShowModal(false)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <div class="scroll-wrapper">
            <iframe class="modal-iframe" src={iframeSrc} title="&nbsp;" />
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
}
