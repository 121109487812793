export default {
  1: {
    id: 1,
    name: 'gender',
    question: 'Please select your gender:',
    subQuestion: '',
    type: 'single',
    answers: [
      {
        id: 1,
        text: 'Male',
        value: 'male',
        sprite: 'man'
      },
      {
        id: 2,
        text: 'Female',
        value: 'female',
        sprite: 'woman'
      }
    ]
  },
  2: {
    id: 2,
    name: 'physical_activity',
    question: 'Which answer best describes your current level of exercise?',
    subQuestion: '',
    type: 'single',
    answers: [
      {
        id: 1,
        text: 'No exercise at all',
        value: '1'
      },
      {
        id: 2,
        text: 'Casual - Go for walks',
        value: '2'
      },
      {
        id: 3,
        text: 'Moderate - Exercise 1-2x / week',
        value: '3'
      },
      {
        id: 4,
        text: 'Vigorous - Exercise 3-4x / week',
        value: '4'
      },
      {
        id: 5,
        text: 'Extreme - Exercise 5x+ / week',
        value: '5'
      }
    ]
  },
  3: {
    id: 3,
    name: 'typical_day',
    question: 'Which answer best describes a typical day for you?',
    subQuestion: '',
    type: 'single',
    answers: [
      {
        id: 1,
        text: 'Sitting (desk job, driving, watching TV)',
        value: '1'
      },
      {
        id: 2,
        text: 'Sitting + on foot (errands or light work)',
        value: '2'
      },
      {
        id: 3,
        text: 'Mostly on foot (low manual labor)',
        value: '3'
      },
      {
        id: 4,
        text: 'On foot (high manual labor)',
        value: '4'
      }
    ]
  },
  4: {
    id: 4,
    name: 'meat',
    question: 'Protein Sources',
    subQuestion:
      'All options are included by default. Select any you prefer to exclude.',
    type: 'multi',
    answers: [
      {
        id: 1,
        text: 'chicken',
        value: 'chicken',
        sprite: 'chicken',
        selected: true
      },
      {
        id: 2,
        text: 'pork',
        value: 'pork',
        sprite: 'pork',
        selected: true
      },
      {
        id: 3,
        text: 'beef',
        value: 'beef',
        sprite: 'beef',
        selected: true
      },
      {
        id: 4,
        text: 'fish',
        value: 'fish',
        sprite: 'fish',
        selected: true
      },
      {
        id: 5,
        text: 'bacon',
        value: 'bacon',
        sprite: 'bacon',
        selected: true
      }
    ]
  },
  5: {
    id: 5,
    name: 'veggies',
    question: 'Nutrient Sources',
    subQuestion:
      'All options are included by default. Select any you prefer to exclude.',
    type: 'multi',
    answers: [
      {
        id: 1,
        text: 'broccoli',
        value: 'broccoli',
        sprite: 'broccoli',
        selected: true
      },
      {
        id: 2,
        text: 'mushrooms',
        value: 'mushrooms',
        sprite: 'mushrooms',
        selected: true
      },
      {
        id: 3,
        text: 'zucchini',
        value: 'zucchini',
        sprite: 'zucchini',
        selected: true
      },
      {
        id: 4,
        text: 'cauliflower',
        value: 'cauliflower',
        sprite: 'cauliflower',
        selected: true
      },
      {
        id: 5,
        text: 'avocado',
        value: 'avocado',
        sprite: 'avocado',
        selected: true
      }
    ]
  },
  6: {
    id: 6,
    name: 'products',
    question: 'Fat Sources',
    subQuestion:
      'All options are included by default. Select any you prefer to exclude.',
    type: 'multi',
    answers: [
      {
        id: 1,
        text: 'egg',
        value: 'egg',
        sprite: 'eggs',
        selected: true
      },
      {
        id: 2,
        text: 'nuts',
        value: 'nuts',
        sprite: 'nuts',
        selected: true
      },
      {
        id: 3,
        text: 'cheese',
        value: 'cheese',
        sprite: 'cheese',
        selected: true
      },
      {
        id: 4,
        text: 'butter',
        value: 'butter',
        sprite: 'butter',
        selected: true
      },
      {
        id: 5,
        text: 'coconut',
        value: 'coconut',
        sprite: 'coconut',
        selected: true
      }
    ]
  }
};
