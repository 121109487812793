import React, { Component } from 'react';
import Question from './Question';
import StepProgressStatic from './StepProgressStatic';
import QuizContext from '../../store/QuizContext';

import { getAnswerByQuestionName } from '../../lib/quizResults';
import { dataLayerPush } from '../../lib/gtm';

export default class Quiz extends Component {
  state = {
    question: null,
    currentId: 0,
    subQuestion: null
  };

  gtm = (questionId = null) => {
    const startQuizQuestionId = 2;
    let gender;

    try {
      gender = getAnswerByQuestionName('gender', this.props.appState.quiz)[0];
    } catch (error) {
      return;
    }
    if (parseInt(questionId) === startQuizQuestionId) {
      dataLayerPush({ event: 'quizStart', gender });
    }
  };

  componentDidMount() {
    const {
      match: { params }
    } = this.props;

    this.gtm(params.questionId);
    let question = this.props.questionById(params.questionId);
    this.setState({ question });
    this.setState({ currentId: question.id });
    this.setState({ subQuestion: question.subQuestion });
  }

  render() {
    return (
      <QuizContext.Consumer>
        {({ questionById, quiz, setGenderBodyClass, saveToQuizLocal }) => {
          this.questionById = questionById;
          return (
            <div className="quiz-page">
              <StepProgressStatic step={this.state.currentId} />
              <Question
                setGenderBodyClass={setGenderBodyClass}
                quiz={quiz}
                currentQuestion={this.state.question}
                subQuestion={this.state.subQuestion}
                saveToQuizLocal={saveToQuizLocal}
              />
            </div>
          );
        }}
      </QuizContext.Consumer>
    );
  }
}
