import React, { Component } from 'react';
import MealPlanContext from './MealPlanContext';
import axios from 'axios';

const API_BASE = process.env.REACT_APP_API_URL;
class MealPlanProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mealPlan: {
        success: false
      },
      meal: {
        success: false
      },
      sessionToken: props.sessionToken
    };
  }

  getMealPlanAPI = orderId => {
    return axios
      .get(`${API_BASE}/mealplans/${orderId}`, {
        params: {
          sessionToken: this.state.sessionToken
        }
      })
      .then(res => {
        this.setState({ mealPlan: res.data });
      });
  };

  getMealAPI = meal_id => {
    axios
      .get(process.env.REACT_APP_API_URL + '/recipe/' + meal_id, {
        params: {
          sessionToken: this.state.sessionToken
        }
      })
      .then(res => {
        this.setState({ meal: res.data });
      });
  };

  clearMeal = () => {
    var meal = { success: false };
    this.setState({ meal });
  };

  render() {
    return (
      <MealPlanContext.Provider
        value={{
          mealPlan: this.state.mealPlan,
          meal: this.state.meal,
          getMealPlanAPI: this.getMealPlanAPI,
          getMealAPI: this.getMealAPI,
          clearMeal: this.clearMeal
        }}
      >
        {this.props.children}
      </MealPlanContext.Provider>
    );
  }
}

export default MealPlanProvider;
