import axios from 'axios';
const API_BASE = process.env.REACT_APP_API_URL;

export function saveEmail(data, token) {
  const { email } = data;
  return axios.put(`${API_BASE}/user_session`, { email, sessionToken: token });
}

export function updateEmail(data, token) {
  const { email, order_id } = data;
  return axios.put(`${API_BASE}/updateEmail`, {
    email,
    sessionToken: token,
    order_id
  });
}

export function selectBiller() {
  return axios.get(`${API_BASE}/payments/select_biller`);
}

export async function capturePayment(data) {
  try {
    let response = await axios.post(`${API_BASE}/payments/capture`, data);
    return response.data;
  } catch (error) {
    throw error.response;
  }
}

export async function chargePayment(data) {
  try {
    let response = await axios.post(`${API_BASE}/payments/charge`, data);
    return response.data;
  } catch (error) {
    throw error.response;
  }
}
